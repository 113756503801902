import './AuthContainer.css'
import React, {Component} from 'react';


class AuthContainer extends Component {

    render() {

        return (
            <div className={"auth-container"}>
                <div className={"form-signin"}>
                    {this.props.children}
                    <p className="mt-5 mb-3 text-muted">© 2022 Цифровой ресурс IPR SMART</p>
                </div>
            </div>
        )
    }

}

export default AuthContainer;