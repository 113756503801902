import {observable, action, reaction, decorate} from 'mobx';

class AppSession {
    token = window.localStorage.getItem('jwt');
    vkUserId = window.localStorage.getItem('vk_user_id');
    userId = window.localStorage.getItem('user_id');
    email = window.localStorage.getItem('email');
    fio = window.localStorage.getItem('fio');
    role = window.localStorage.getItem('role');

    user;

    constructor() {
        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                } else {
                    window.localStorage.removeItem('jwt');
                }
            }
        );

        reaction(
            () => this.user,
            (user) => {
                if (user) {
                    this.userId = user.id;
                    this.email = user.email;
                    this.fio = user.name;
                    this.role = user.role;

                    window.localStorage.setItem('user_id', this.userId);
                    window.localStorage.setItem('email', this.email);
                    window.localStorage.setItem('fio', this.fio);
                    window.localStorage.setItem('role', this.role);
                } else {
                    window.localStorage.removeItem('user_id');
                    window.localStorage.removeItem('email');
                    window.localStorage.removeItem('fio');
                    window.localStorage.removeItem('role');
                }
            }
        );

        reaction(
            () => this.vkUserId,
            (vkUserId) => {
                if (vkUserId) {
                    window.localStorage.setItem('vk_user_id', vkUserId);
                } else {
                    window.localStorage.removeItem('vk_user_id');
                }
            }
        );
    }

    setToken(token) {
        this.token = token;
    }

    setUser(user) {
        this.user = user;
    }

    setVkUserId(vkUserId) {
        this.vkUserId = vkUserId;
    }

    reset() {
        this.token = null;
        this.vkUserId = null;
        this.user = null;
        this.userId = null;
        this.email = null;
        this.fio = null;
        this.role = null;
    }
}

decorate(AppSession, {
    token: observable,
    vkUserId: observable,
    user: observable,
    setToken: action,
    setUser: action,
    setVkUserId: action,
    reset: action,
});

export default new AppSession();