import React, {Component} from 'react'
import ReactModal from 'react-modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVirus} from "@fortawesome/free-solid-svg-icons/faVirus";
import {faMailBulk} from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {inject, observer} from "mobx-react";
import request from "../../../utils/request";
import {store} from "react-notifications-component";

const animatedComponents = makeAnimated();

class InviteVkDialog extends Component {

    state = {
        showModal: false,
        friends: [],
        selectedFriends: [],
    };

    handleOpenModal = () => {
        this.loadFriends();
        this.setState({showModal: true});
    }

    handleCloseModal = () => {
        this.setState({showModal: false});
    }

    handleChangeFriends = (e) => {
        this.setState({selectedFriend: e.currentTarget.value});
    }

    onChange = (a, b) => {
        if (b.action === "clear") {
            this.setState({selectedFriends: []});
            return;
        }

        let val = 0;
        if (b.option) {
            val = b.option.value;
        } else {
            val = b.removedValue.value
        }

        if (!this.state.selectedFriends.includes(val)) {
            this.setState(prevState => ({
                selectedFriends: [...prevState.selectedFriends, val]
            }));
        } else {
            let tmp = this.state.selectedFriends;
            tmp.splice(tmp.indexOf(val), 1)

            this.setState(prevState => ({
                selectedFriends: tmp
            }));
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        return request({
            method: 'POST',
            path: '/invitations/vk/send',
            body: {
                friends: this.state.selectedFriends,
                group_id: this.props.groupId
            }
        })
            .then((resp) => {
                this.handleCloseModal();
                this.setState({friends: []});

                store.addNotification({
                    message: resp.meta.message,
                    type: "info",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    loadFriends() {
        const VK = window.VK;
        let self = this;

        try {
            VK.init(function () {
            }, function () {
            }, '5.107');

            VK.api("friends.get", {
                "user_id": this.props.appSession.vkUserId,
                "fields": "nickname",
                "order": "name",
                "v": "5.73"
            }, function (data) {
                self.setState({friends: data.response.items});
            });
        } catch (e) {
            console.log("NOT IN VK.COM");
        }
    }

    render() {
        const options = [
            // {label: 'Thing 1', value: 1},
            // {label: 'Thing 2', value: 2},
            // {label: 'aa 2', value: 3},
            // {label: 'bb 2', value: 4},
            // {label: 'cc 2', value: 5},
            // {label: 'dd 2', value: 6},
            // {label: 'ee 2', value: 7},
        ];

        return (
            <div>
                <button type="button" className="btn btn-outline-secondary ml-2" onClick={this.handleOpenModal}>
                    <FontAwesomeIcon icon={faVirus}/>
                </button>
                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Пригласить друзей вконтакте</h5>
                            <button type="button" className="close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div>
                            <div className="modal-body">
                                <form className="form" id="friends-form">
                                    <p> Приглашение будет отправленно пользователям
                                        установившим приложение и разрешившим принимать сообщения от группы ЭБС IPR
                                        BOOKS вконтакте.</p>
                                    <br/>
                                    <div className="row">
                                        <div className="col-sm-7 offset-1">
                                            <div className="form-group">
                                                {
                                                    this.state.friends.map(item => {
                                                            options.push({
                                                                label: item.first_name + " " + item.last_name,
                                                                value: item.id
                                                            })
                                                        }
                                                    )
                                                }

                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    placeholder={"Список друзей Вконтакте"}
                                                    isMulti
                                                    options={options}
                                                    onChange={this.onChange}
                                                />

                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <button onClick={this.handleSubmit}
                                                        className="btn btn-outline-warning btn-block">
                                                    <FontAwesomeIcon icon={faMailBulk}/> Пригласить
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                        onClick={this.handleCloseModal}>
                                    Закрыть окно
                                </button>
                            </div>
                        </div>
                    </div>
                </ReactModal>
            </div>
        )
            ;
    }
}

export default inject('auth', 'appSession')(observer(InviteVkDialog));
