import React, {Component} from 'react'
import ReactAutocomplete from 'react-autocomplete'
import request from "../utils/request";

class BookAutocomplete extends Component {

    state = {
        value: this.props.bookTitle,
        items: [],
        total: 0
    }

    handleChange = (event) => {
        this.setState({value: event.target.value});
        if (event.target.value.length > 3) {
            this.getItems(event.target.value).then(value => {
            });
        }
    }

    handleSelect = (value, item) => {
        this.setState({value});
        this.props.onChange(item);
    }

    getItems(search) {
        return request({
            method: 'GET',
            path: '/books/autocomplete',
            params: {
                'search': search
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    render() {
        return (
            <ReactAutocomplete
                items={this.state.items}
                wrapperStyle={{
                    display: "block",
                    width: "100%"
                }}
                menuStyle={{
                    zIndex: '999',
                    borderRadius: '3px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: 'rgba(255, 255, 255, 0.9)',
                    padding: '2px 0',
                    fontSize: '90%',
                    position: 'fixed',
                    overflow: 'auto',
                    maxHeight: '40%',
                }}
                shouldItemRender={
                    (item, value) => item.title.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={item => item.title}
                renderItem={(item, highlighted) =>
                    <div
                        key={item.id}
                        style={{
                            backgroundColor: highlighted ? '#eee' : '#fff', zIndex: 999,
                            cursor: "default",
                            padding: "10px"

                        }}>
                        {item.title}
                    </div>
                }
                value={this.state.value}
                autoHighlight={true}

                onChange={this.handleChange}
                onSelect={this.handleSelect}
            />
        )
    }
}

export default BookAutocomplete