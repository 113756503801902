import React from 'react';
import ReactPaginate from "react-paginate";
import request from "../../../utils/request";
import Loader from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faSync} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "react-bootstrap";
import AddToGroupsDialog from "./AddToGroupsDialog";


class Students extends React.Component {

    state = {
        items: [],
        total: 0,
        countPerPage: 10,
        currentPage: 0,
        isLoading: true
    }

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected,
            isLoading: true
        });
        this.getItems(this.state.countPerPage, this.state.countPerPage * page.selected).then();
    }

    handleBlock = (event) => {
        return request({
            method: 'GET',
            path: '/students/block',
            params: {
                user_id: event.currentTarget.dataset.id
            }
        })
            .then((resp) => {
                this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then();
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    getItems(limit, offset) {
        return request({
            method: 'GET',
            path: '/students',
            params: {
                'limit': limit,
                'offset': offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then();
    }

    render() {
        const {items, isLoading} = this.state;

        if (isLoading) {
            return (
                <div className="col-sm-8 pl-0 offset-sm-1"
                     style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader
                        type="BallTriangle"
                        color="#0f7dff"
                        height={50}
                        width={50}
                    />
                </div>
            );
        }

        return (
            <div className="col-sm-8 pl-0 offset-sm-1">

                {
                    items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }


                {
                    items.length > 0 ? (
                        <table className="table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Данные обучающегося</th>
                                <th>Дата регистрации</th>
                                <th>Статус</th>
                                <th>Действия</th>
                            </tr>
                            </thead>
                            <tbody id="users-list">

                            {
                                items.map(item =>
                                    <tr key={item.user_id}>
                                        <td>
                                            <strong>ФИО:</strong> {item.user_fio}<br/>
                                            <strong>E-mail:</strong> {item.user_email}<br/>
                                        </td>
                                        <td>{item.user_date}</td>
                                        <td>{item.user_blocked === 0 ? "Активен" : "Заблокирован"}</td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic"
                                                                 variant={"btn btn-block btn-sm btn-outline-warning"}>
                                                    <FontAwesomeIcon icon={faCog}/> Действия
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item data-id={item.user_id}
                                                                   onClick={this.handleBlock}>
                                                        <FontAwesomeIcon icon={faSync}/> Изменить статус
                                                        пользователя
                                                    </Dropdown.Item>

                                                    <AddToGroupsDialog studentId={item.user_id}/>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </td>
                                    </tr>
                                )
                            }

                            </tbody>
                        </table>
                    ) : (
                        <div style={{width: "100%"}}>
                            <br/>
                            <h3 style={{textAlign: "center"}}>У Вас нет ни одного студента</h3>
                        </div>
                    )
                }


                {
                    items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

            </div>
        );
    }
}

export default Students