import React from "react";
import {BrowserRouter, NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVirus} from "@fortawesome/free-solid-svg-icons";
import {inject} from "mobx-react";


class NavView extends React.Component {

    render() {
        return (
            <ul className="nav nav-pills flex-column">
                {/*{parseInt(this.props.appSession.role) === 1 && <li className="nav-item">*/}
                {/*    <NavLink className="nav-link" to={"/invitations"}>Пригласить студентов</NavLink>*/}
                {/*</li>}*/}
                <li className="nav-item">
                    <NavLink className="nav-link" to={"/groups"}>Мои группы</NavLink>
                </li>
                {parseInt(this.props.appSession.role) === 1 && <li className="nav-item">
                    <NavLink className="nav-link" to={"/students"}>Мои студенты</NavLink>
                </li>}
                <li className="nav-item">
                    <NavLink className="nav-link" to={"/tasks"}>Мои задания</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to={"/favorites"}>Мои закладки</NavLink>
                </li>

                <li className="nav-item">
                    <BrowserRouter>
                        <a className="nav-link btn btn-success btn-block" target="_blank"
                           href="https://vk.com/iprmedia" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faVirus}/> Сообщество IPR MEDIA
                        </a>
                    </BrowserRouter>
                </li>
            </ul>
        );
    }
}

export default inject('appSession')(NavView);

