import React from 'react';
import {observer, inject} from 'mobx-react';
import AuthContainer from '../../../components/AuthContainer';
import LogoTitle from "../../../components/LogoTitle";
import {Link} from "react-router-dom";
import history from "../../history";
import request from "../../../utils/request";
import {store} from "react-notifications-component";
import Loader from "react-loader-spinner";


class Register extends React.Component {

    state = {
        isLoading: false,
        groupId: 0,
        checked: false,
        code: '',
        inputType: 'text',
        role: 2,
        fio: '',
        email: '',
        password: ''
    };

    handleCodeChange = (event) => {
        this.setState({code: event.currentTarget.value});
    }

    checkCode(code) {
        return request({
            method: 'POST',
            path: '/auth/code/check',
            params: {
                code: code
            }
        })
            .then((resp) => {
                if (resp.meta.success) {
                    this.setState({
                        groupId: resp.data.id,
                        checked: true
                    });
                } else {
                    this.showError(resp.meta.message);
                }

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    handleCheckCode = (event) => {
        event.preventDefault();
        this.checkCode(this.state.code).then();
    }

    // handleRoleChange = (role) => {
    //     this.setState({role: role});
    // }

    handleFioChange = (event) => {
        this.setState({fio: event.currentTarget.value});
    }

    handleEmailChange = (event) => {
        this.setState({email: event.currentTarget.value});
    }

    handlePasswordChange = (event) => {
        this.setState({
            inputType: event.currentTarget.value.length === 0 ? 'text' : 'password',
            password: event.currentTarget.value
        });
    }

    handleRegister = (event) => {
        event.preventDefault();
        if (!this.validateFio(this.state.fio) || !this.validateEmail(this.state.email) || !this.validatePassword(this.state.password)) {
            return;
        }

        this.setState({isLoading: true});

        return request({
            method: 'POST',
            path: '/auth/registration',
            params: {
                group_id: this.state.groupId,
                role: this.state.role,
                fio: this.state.fio,
                email: this.state.email,
                password: this.state.password
            }
        })
            .then((resp) => {
                if (!resp.meta.success) {
                    this.setState({isLoading: false});
                    this.showError(resp.meta.message);
                } else {
                    let body = {
                        email: this.state.email,
                        password: this.state.password
                    };

                    return this.props.auth.login(body, 'login').then((result) => {
                        if (result instanceof Error) {
                            this.setState({isLoading: false});
                            return;
                        }

                        this.props.auth.me().then(value => {
                            this.setState({isLoading: false});
                            history.push('/');
                        })
                    });
                }

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var success = re.test(String(email).toLowerCase());
        if (!success) {
            this.showError('Некорректный Email');
        }

        return success;
    }

    validateFio(fio) {
        if (fio.length === 0) {
            this.showError('Не заполнено ФИО');
            return false;
        } else {
            return true;
        }
    }

    validatePassword(password) {
        if (password.length < 6) {
            this.showError('Пароль не менее 6 символов');
            return false;
        } else {
            return true;
        }
    }

    showError(text) {
        store.addNotification({
            message: text,
            type: "warning",
            insert: "top",
            container: "bottom-center",
            dismiss: {
                duration: 2000,
            }
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <AuthContainer>
                    <LogoTitle title="регистрация"/>
                    <div
                        className="col-sm-8 pl-0 offset-sm-1"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '400px',
                            margin: 'auto'
                        }}>
                        <Loader
                            type="BallTriangle"
                            color="#0f7dff"
                            height={100}
                            width={100}
                        />
                    </div>
                </AuthContainer>

            );
        }

        if (!this.state.checked) {
            return (
                <AuthContainer>
                    <LogoTitle title="регистрация" subtitle="У Вас есть код приглашения?"/>
                    <form>
                        <label htmlFor="inputEmail" className="sr-only">Введите приглашения</label>
                        <input type="text" id="inputEmail" className="form-control mb-3"
                               onChange={this.handleCodeChange}
                               placeholder="Введите полученный код" required=""/>

                        <div className="checkbox mb-5">
                            <label className="mb-2 d-block">
                                <Link to="/login" className="text-underline">← Вернуться к авторизации</Link>
                            </label>
                        </div>

                        <button className="btn btn-lg btn-success text-uppercase" onClick={this.handleCheckCode}>
                            Продолжить
                        </button>
                    </form>
                </AuthContainer>
            );
        } else {
            return (
                <AuthContainer>
                    <LogoTitle title="регистрация"/>
                    <form>
                        {/*<RoleSwitch callback={this.handleRoleChange}/>*/}
                        <label htmlFor="inputFio" className="sr-only">Фамилия Имя Отчество</label>
                        <input type="text" id="inputFio" className="form-control mb-3"
                               onChange={this.handleFioChange}
                               placeholder="Фамилия Имя Отчество" required=""/>

                        <label htmlFor="inputEmail" className="sr-only">Email</label>
                        <input type="email" id="inputEmail" className="form-control mb-3"
                               onChange={this.handleEmailChange}
                               placeholder="Email" required=""/>

                        <label htmlFor="inputPassword" className="sr-only">Пароль</label>
                        <input type={this.state.inputType} id="inputEmail" className="form-control mb-3"
                               onChange={this.handlePasswordChange}
                               placeholder="Пароль" required=""/>

                        <button className="btn btn-lg btn-success text-uppercase" onClick={this.handleRegister}>
                            Зарегистрироваться
                        </button>

                    </form>
                </AuthContainer>
            );
        }
    }
}

export default inject('auth')(observer(Register));
