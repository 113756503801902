import React from 'react';
import {observer, inject} from 'mobx-react';
import Protected from "./Protected";

class RoleSwitchRoute extends React.Component {
    render() {
        const {
            appSession,
            teacher: TeacherComponent,
            student: StudentComponent,
            to,
            exact
        } = this.props;

        return (
            <Protected
                to={to}
                exact={exact}
                render={(props) => {
                    return parseInt(appSession.role) === 1 ? (<TeacherComponent {...props} />) : (
                        <StudentComponent {...props} />);
                }}
            />
        );
    }
}

export default inject('appSession')(observer(RoleSwitchRoute));