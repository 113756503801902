import '../theme/semantic.less';

import {Provider} from 'mobx-react';
import {Router, Route, Switch} from 'react-router-dom';
import {configure} from 'mobx';
import React from 'react';
import {hot} from 'react-hot-loader';

import AuthSwitchRoute from '../components/routes/AuthSwitch';


import Login from './Auth/Login';
import AuthStore from '../stores/Auth';
import appSession from '../stores/AppSession';
import Register from "./Auth/Register";
import Recovery from "./Auth/Recovery";
import Logout from "./Auth/Logout";
import Page from "../components/Page";

import history from './history'
import SearchStore from "../stores/SearchStore";


configure({
    enforceActions: 'observed'
});

const stores = {
    appSession,
    auth: new AuthStore(),
    searchStore: new SearchStore()
};

const App = () => (
    <Provider {...stores}>
        <Router history={history}>
            <Switch>
                <AuthSwitchRoute
                    exact
                    path="/"
                    loggedIn={Page}
                    loggedOut={Login}/>

                <Route exact path="/login" component={Login}/>
                <Route exact path="/register" component={Register}/>
                <Route exact path="/recovery" component={Recovery}/>
                <Route exact path="/logout" component={Logout}/>
            </Switch>
        </Router>
    </Provider>
);

export default hot(module)(App);