import React, {Component} from 'react'
import ReactModal from 'react-modal';
import defaultImage from '../../../assets/img/default.png'
import request from "../../../utils/request";
import ReactPaginate from "react-paginate";
import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {store} from "react-notifications-component";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import Loader from "react-loader-spinner";
import InviteVkDialog from "./InviteVkDialog";


class DetailsDialog extends Component {

    state = {
        showModal: false,
        teacherImg: defaultImage,
        items: [],
        total: 0,
        search: "",
        typing: false,
        typingTimeout: 0,
        addStudents: [],
        countPerPage: 6,
        currentPage: 0,
        loading: true,
    };

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected,
            loading: true,
        });
        this.getItems(this.state.countPerPage, this.state.countPerPage * page.selected).then(value => {
        });
    }

    getItems(limit, offset) {
        return request({
            method: 'GET',
            path: '/groups/students',
            params: {
                group_id: this.props.groupId,
                limit: limit,
                offset: offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    loading: false,
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    getAddStudents(search) {
        return request({
            method: 'GET',
            path: '/groups/addstudents',
            params: {
                group_id: this.props.groupId,
                search: search
            }
        })
            .then((resp) => {
                this.setState({
                    loading: false,
                    addStudents: resp.data.items
                });

                this.nameInput.focus();
                return resp
            })
            .catch((err) => {
                return err;
            });
    }

    handleOpenModal = () => {
        this.setState({showModal: true});
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then();
        this.getAddStudents("").then();
    }

    handleCloseModal = () => {
        this.setState({showModal: false});
    }

    handleChangeSearch = (e) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.setState({loading: true});
                self.getAddStudents(self.state.search).then();
            }, 800)
        });
    }

    handleAddStudent = (e) => {
        return request({
            method: 'GET',
            path: '/groups/students/add',
            params: {
                group_id: this.props.groupId,
                student_id: e.currentTarget.dataset.id
            }
        })
            .then((resp) => {
                store.addNotification({
                    message: resp.meta.message,
                    type: resp.meta.success ? "info" : "danger",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

                if (resp.meta.success) {
                    this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then();
                    this.getAddStudents(this.state.search).then();
                }

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    handleRemove = (e) => {
        return request({
            method: 'GET',
            path: '/groups/students/remove',
            params: {
                group_id: this.props.groupId,
                student_id: e.currentTarget.dataset.id
            }
        })
            .then((resp) => {
                store.addNotification({
                    message: resp.meta.message,
                    type: resp.meta.success ? "info" : "danger",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

                if (resp.meta.success) {
                    let page = this.state.currentPage;
                    if (this.state.items.length === 1 && this.state.currentPage > 0) {
                        page--;
                        this.setState({currentPage: page});
                    }

                    this.getItems(this.state.countPerPage, this.state.countPerPage * page).then();
                    this.getAddStudents(this.state.search).then();
                }

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }


    render() {
        return (
            <div>
                {
                    parseInt(this.props.appSession.role) === 1 ? (
                        <div>
                            <button className="btn btn-block btn-outline-warning"
                                    onClick={this.handleOpenModal}>
                                <FontAwesomeIcon icon={faList}/> Обучающиеся
                            </button>
                            {this.props.children}
                        </div>
                    ) : (
                        <div className="row" onClick={this.handleOpenModal}>
                            <div className="col-sm-12">
                                <div className="col-sm-8">
                                    Группа: {this.props.groupTitle}
                                    <br/>
                                    Описание: {this.props.groupDescr}
                                </div>
                            </div>
                        </div>
                    )
                }

                <ReactModal
                    id={"groupModal"}
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Группа: {this.props.groupTitle}</h5>
                            <button type="button" className="close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body p-5">
                            <Tabs>
                                <TabList>
                                    <Tab>Обучающиеся в группе</Tab>
                                    {parseInt(this.props.appSession.role) === 1 && <Tab>Пригласить / добавить</Tab>}
                                </TabList>

                                <TabPanel>
                                    <br/>
                                    <div>
                                        {
                                            this.state.loading ? (
                                                <div style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <Loader
                                                        type="BallTriangle"
                                                        color="#0f7dff"
                                                        height={50}
                                                        width={50}/>
                                                </div>
                                            ) : (
                                                this.state.items.length > 0 ? (
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <tr>
                                                            <th>Данные обучающегося</th>
                                                            <th>Дата регистрации</th>
                                                            <th>Статус</th>
                                                            {parseInt(this.props.appSession.role) === 1 &&
                                                            <th>Действия</th>}
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {
                                                            this.state.items.map((item, index, arr) =>
                                                                <tr key={item.id}>
                                                                    <td>
                                                                        ФИО: {item.name}
                                                                        <br/>
                                                                        E-mail: {item.email}
                                                                    </td>
                                                                    <td>{item.user_date}</td>
                                                                    <td>{item.blocked === 0 ? "Активен" : "Заблокирован"}</td>
                                                                    {parseInt(this.props.appSession.role) === 1 &&
                                                                    <td>
                                                                        <button type="button"
                                                                                className="btn btn-sm btn-outline-secondary"
                                                                                data-id={item.id}
                                                                                onClick={this.handleRemove}>
                                                                            <FontAwesomeIcon
                                                                                icon={faTrash}/> Удалить
                                                                        </button>
                                                                    </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        }

                                                        </tbody>
                                                    </table>
                                                ) : (

                                                    <div style={{width: "100%"}}>
                                                        <br/>
                                                        <h3 style={{textAlign: "center"}}>В группе нет
                                                            студентов</h3>
                                                    </div>
                                                )
                                            )

                                        }
                                    </div>

                                    {
                                        this.state.items.length > 0 &&
                                        <ReactPaginate
                                            forcePage={this.state.currentPage}
                                            pageCount={this.state.total / this.state.countPerPage}
                                            pageRangeDisplayed={2}
                                            marginPagesDisplayed={2}
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            containerClassName={"pagination justify-content-center m-4"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link prev"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link next"}
                                            onPageChange={this.onPageChange}/>
                                    }
                                </TabPanel>
                                <TabPanel>

                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <label htmlFor="inputTitle">Код приглашения в группу</label>
                                            <div className="d-inline-flex">
                                                <input type="text"
                                                       className="form-control text-center"
                                                       disabled
                                                       value={this.props.groupCode}/>

                                                <CopyToClipboard
                                                    text={this.props.groupCode}
                                                    onCopy={() => {
                                                        store.addNotification({
                                                            message: "Код приглашения скопирован",
                                                            type: "info",
                                                            insert: "top",
                                                            container: "bottom-center",
                                                            dismiss: {
                                                                duration: 2000,
                                                            }
                                                        });
                                                    }}>
                                                    <button type="button"
                                                            className="btn btn-outline ml-2">
                                                        <FontAwesomeIcon icon={faCopy}/>
                                                    </button>
                                                </CopyToClipboard>


                                                <InviteVkDialog
                                                    groupId={this.props.groupId}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            Скопируйте и отправьте код обучающимся, чтобы они вступили в группу для
                                            получения заданий
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <input type="text"
                                               className="form-control"
                                               id="inputSearch"
                                               onChange={this.handleChangeSearch}
                                               value={this.state.search}
                                               ref={(input) => {
                                                   this.nameInput = input;
                                               }}
                                               placeholder="Поиск обучающихся по ФИО или E-mail"/>
                                    </div>


                                    <div className="mt-4">

                                        {
                                            this.state.loading ? (
                                                <div style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <Loader
                                                        type="BallTriangle"
                                                        color="#0f7dff"
                                                        height={50}
                                                        width={50}/>
                                                </div>
                                            ) : (
                                                this.state.addStudents.length > 0 ? (
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <tr>
                                                            <th>Данные обучающегося</th>
                                                            <th>Дата регистрации</th>
                                                            <th>Статус</th>
                                                            <th>Действия</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            this.state.addStudents.map((item, index, arr) =>
                                                                <tr key={item.id}>
                                                                    <td>
                                                                        ФИО: {item.name}
                                                                        <br/>
                                                                        E-mail: {item.email}
                                                                    </td>
                                                                    <td>{item.user_date}</td>
                                                                    <td>{item.blocked === 0 ? "Активен" : "Заблокирован"}</td>
                                                                    <td>
                                                                        <button
                                                                            className="btn btn-sm btn-outline-warning"
                                                                            data-id={item.id}
                                                                            onClick={this.handleAddStudent}>
                                                                            <FontAwesomeIcon
                                                                                icon={faPlus}/> Добавить
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    this.state.search.length > 0 ? (
                                                        <div style={{width: "100%"}}>
                                                            <br/>
                                                            <h3 style={{textAlign: "center"}}>По вашему запросу
                                                                ничего не
                                                                найдено</h3>
                                                        </div>
                                                    ) : (
                                                        <div style={{width: "100%"}}>
                                                            <br/>
                                                            <h3 style={{textAlign: "center"}}>Нет студентов</h3>
                                                        </div>
                                                    )
                                                )
                                            )

                                        }

                                    </div>

                                </TabPanel>
                            </Tabs>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>
                                Закрыть окно
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

export default inject('appSession')(observer(DetailsDialog))
