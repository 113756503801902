import {action, decorate, observable} from "mobx";

class SearchStore {
    action;
    search;
    type;

    constructor() {
        this.action = "";
        this.search = "";
        this.type = 1
    }

    setAction(action) {
        this.action = action
    }

    setSearch(search) {
        this.search = search;
    }

    setType(type) {
        this.type = type;
    }

    reset() {
        this.search = "";
        this.type = 1
    }
}

decorate(SearchStore, {
    action: observable,
    search: observable,
    type: observable,
    setAction: action,
    setSearch: action,
    setType: action,
    reset: action
})

export default SearchStore
