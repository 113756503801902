import React from 'react';
import Filter from "./Filter";
import Create from "./Create";
import ReactPaginate from "react-paginate";
import request from "../../../utils/request";
import Loader from "react-loader-spinner";


class Invitations extends React.Component {

    state = {
        items: [],
        total: 0,
        countPerPage: 10,
        currentPage: 0,
        isLoading: true
    }

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected,
            isLoading: true
        });
        this.getItems(this.state.countPerPage, this.state.countPerPage * page.selected).then(value => {
        });
    }

    handleCreateInvitation = () => {
        this.setState({
            currentPage: 0,
            isLoading: true
        })
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then(value => {
        });
    }

    export = () => {
        return request({
            method: 'GET',
            path: '/invitations/export'
        })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    getItems(limit, offset) {
        return request({
            method: 'GET',
            path: '/invitations',
            params: {
                'limit': limit,
                'offset': offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then(value => {
        });
    }

    render() {
        const {items, isLoading} = this.state;

        if (isLoading) {
            return (
                <div className="col-sm-8 pl-0 offset-sm-1"
                     style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader
                        type="BallTriangle"
                        color="#0f7dff"
                        height={50}
                        width={50}
                    />
                </div>
            );
        }

        return (
            <div className="col-sm-8 pl-0 offset-sm-1">
                <Create callback={this.handleCreateInvitation}/>
                <Filter callback={this.export}/>

                <ReactPaginate
                    forcePage={this.state.currentPage}
                    pageCount={this.state.total / this.state.countPerPage}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    previousLabel={"<"}
                    nextLabel={">"}
                    containerClassName={"pagination justify-content-center m-4"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link prev"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link next"}
                    onPageChange={this.onPageChange}/>

                <table className="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>Код приглашения</th>
                        <th>Время истечения срока действия</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody id="codes-list">

                    {

                        items.map(item =>
                            <tr key={item.id} id="code-1769">
                                <td><strong>{item.code}</strong></td>
                                <td>{item.date_exp}</td>
                                <td>{item.used === 0 ? 'Активен' : 'Погашен'}</td>
                            </tr>
                        )
                    }

                    </tbody>
                </table>

                <ReactPaginate
                    forcePage={this.state.currentPage}
                    pageCount={this.state.total / this.state.countPerPage}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    previousLabel={"<"}
                    nextLabel={">"}
                    containerClassName={"pagination justify-content-center m-4"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link prev"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link next"}
                    onPageChange={this.onPageChange}/>

            </div>
        );
    }

}

export default Invitations