import React, {Component} from 'react'
import ReactModal from 'react-modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {faList} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import request from "../../../utils/request";

class AssignDialog extends Component {

    state = {
        items: [],
        total: 0,
        countPerPage: 6,
        currentPage: 0
    };

    handleOpenModal = () => {
        this.setState({showModal: true});
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then(value => {
        });
    }

    handleCloseModal = () => {
        this.setState({showModal: false});
    }

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected
        });
        this.getItems(this.state.countPerPage, this.state.countPerPage * page.selected).then(value => {
        });
    }

    getItems(limit, offset) {
        return request({
            method: 'GET',
            path: '/groups',
            params: {
                limit: limit,
                offset: offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }


    render() {
        return (
            <div style={{margin: "8px 0"}}>

                <button className="btn btn-block btn-outline-warning" onClick={this.handleOpenModal}>
                    <FontAwesomeIcon icon={faList}/> Отправить группе
                </button>

                <ReactModal
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    ariaHideApp={false}
                    className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Отправить группе</h5>
                            <button type="button" className="close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form id="groupAddForm">
                            <div className="modal-body">

                                <ReactPaginate
                                    forcePage={this.state.currentPage}
                                    pageCount={this.state.total / this.state.countPerPage}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    containerClassName={"pagination justify-content-center m-4"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link prev"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link next"}
                                    onPageChange={this.onPageChange}/>

                                <div id="groups">

                                    {
                                        this.state.items.map(item =>
                                            <div className="group-block" key={item.id} data-id={item.id}
                                                 onClick={(e) => {
                                                     this.props.callback(this.props.taskId, parseInt(e.currentTarget.dataset.id));
                                                     this.handleCloseModal();
                                                 }}>
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        {item.title}<br/>
                                                    </div>
                                                    <div className="col-sm-4">

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </form>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

export default AssignDialog