import {Component} from 'react'
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faMailBulk} from "@fortawesome/free-solid-svg-icons";
import request from "../../../utils/request";
import {store} from "react-notifications-component";
import {inject, observer} from "mobx-react";


class Create extends Component {

    state = {
        type: 1,
        count: 0,
        friends: []
    }

    handleChangeType = (event) => {
        this.setState({type: event.currentTarget.value})
    }

    handleChangeCount = (event) => {
        this.setState({count: event.currentTarget.value})
    }

    handleClickCreateInvitation = (event) => {
        event.preventDefault();
        if (this.state.count > 0) {
            this.createInvitation(this.state.type, this.state.count).then(value => {
                this.props.callback();
            });
        }
    }

    handleChangeFriends = (event) => {

    }

    handleChangeCountVk = (event) => {

    }

    // loadFriends() {
    //     const VK = window.VK;
    //     var create = this;
    //
    //     try {
    //         VK.init(function () {
    //         }, function () {
    //         }, '5.107');
    //
    //         VK.api("friends.get", {
    //             "user_id": this.props.appSession.vkUserId,
    //             "fields": "nickname",
    //             "v": "5.73"
    //         }, function (data) {
    //             create.setState({friends: data.response.items});
    //         });
    //
    //     } catch (e) {
    //         console.log("NOT IN VK.COM");
    //     }
    // }

    createInvitation(type, count) {
        return request({
            method: 'GET',
            path: '/invitations/create',
            params: {
                type: type,
                count: count
            }
        })
            .then((resp) => {

                store.addNotification({
                    message: resp.meta.message,
                    type: "info",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    componentDidMount() {
        // this.loadFriends();
    }

    render() {
        return (
            <div>
                <h4 className="header-light">Создание новых приглашений:</h4>
                <form className="form" id="codes-form">
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="form-group">
                                <label className="control-label">Тип приглашения</label><br/>
                                <select onChange={this.handleChangeType} name="user_type" className="form-control">
                                    <option value="1">Приглашения для студентов</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group ">
                                <label className="control-label">Количество</label><br/>
                                <input onChange={this.handleChangeCount} type="number" min="1" name="count"
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label className="control-label">Действия</label><br/>
                                <button onClick={this.handleClickCreateInvitation}
                                        className="btn btn-outline-warning btn-block">
                                    <FontAwesomeIcon icon={faCheck}/> Создать
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <br/>

                <h4 className="header-light">Пригласить друзей вконтакте:</h4>
                <form className="form" id="friends-form">
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="form-group">
                                <label className="control-label">Список друзей Вконтакте</label><br/>
                                <select onChange={this.handleChangeFriends} name="friends" className="form-control">
                                    <option selected disabled hidden>Выбрать друзей Вконтакте</option>
                                    {
                                        this.state.friends.map(item =>
                                            <option key={item.id} value={item.id}>
                                                {item.first_name + " " + item.last_name}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group ">
                                <label className="control-label">Количество</label><br/>
                                <input onChange={this.handleChangeCountVk} type="number" min="1" name="count_vk"
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label className="control-label">Действия</label><br/>
                                <button onClick={this.handleClickCreateInvitation}
                                        className="btn btn-outline-warning btn-block">
                                    <FontAwesomeIcon icon={faMailBulk}/> Отправить
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default inject('auth', 'appSession')(observer(Create));