import React from 'react';
import {observer, inject} from 'mobx-react';
import {Route} from 'react-router-dom';
import history from "../../App/history";


class AuthSwitchRoute extends React.Component {
    render() {
        const {appSession, ...rest} = this.props;
        if (!appSession.token) {
            return history.push('/');
        }
        return <Route {...rest} />;
    }
}

export default inject('appSession')(observer(AuthSwitchRoute))