import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'react-notifications-component/dist/theme.css'
import App from './App';
import ReactNotification from 'react-notifications-component'
import * as serviceWorker from './serviceWorker';
import bridge from "@vkontakte/vk-bridge";


bridge.subscribe((e) => console.log(e));
bridge.send("VKWebAppInit", {});
bridge.send("VKWebAppAllowMessagesFromGroup", {"group_id": 40448015, "key": "test"});

ReactDOM.render(
    <BrowserRouter>
        <ReactNotification/>
        <App/>
    </BrowserRouter>,
    document.getElementById('root')
);

serviceWorker.unregister();
