import {action, decorate} from 'mobx';
import BaseStore from './BaseStore';
import request from '../utils/request';
import appSession from '../stores/AppSession';
import bridge from '@vkontakte/vk-bridge';


class AuthStore extends BaseStore {

    login(body) {
        return request({
            method: 'POST',
            path: '/auth/login',
            body: body
        })
            .then((resp) => {
                if (!resp.meta.success) {
                    return resp;
                }

                appSession.reset();
                appSession.setToken(resp.data.access_token);
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    resetPassword() {
        return request({
            method: 'POST',
            path: '/auth/password/reset'
        })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    me() {
        return request({
            method: 'POST',
            path: '/auth/me'
        })
            .then((resp) => {
                appSession.setUser(resp);

                bridge.send("VKWebAppInit", {});
                bridge.send("VKWebAppGetUserInfo", {}).then(value => {
                    appSession.setVkUserId(value.id)
                });
            })
            .catch((err) => {
                return err;
            });
    }

}

decorate(AuthStore, {
    login: action,
    me: action
});

export default AuthStore