import React, {Component} from 'react'
import ReactModal from 'react-modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import request from "../../../utils/request";
import {store} from 'react-notifications-component';

class AddGroupDialog extends Component {

    state = {
        showModal: false,
        name: "",
        description: ""
    };

    handleOpenModal = () => {
        this.setState({showModal: true});
    }

    handleCloseModal = () => {
        this.setState({showModal: false});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.name.length > 0) {
            this.createGroup(this.state.name, this.state.description).then(value => {
                this.props.callback();
                this.handleCloseModal();

                store.addNotification({
                    message: value.meta.message,
                    type: "info",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

            });
        }
    }

    handleNameChange = (event) => {
        this.setState({name: event.target.value});
    }

    handleDescriptionChange = (event) => {
        this.setState({description: event.target.value});
    }

    createGroup(name, description) {
        return request({
            method: 'GET',
            path: 'groups/create',
            params: {
                name: name,
                description: description
            }
        })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    render() {
        return (
            <div>
                <button type="button" className="btn btn-outline-warning mb-2" onClick={this.handleOpenModal}>
                    <FontAwesomeIcon icon={faPlus}/> Добавить группу
                </button>
                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Создание новой группы обучающихся</h5>
                            <button type="button" className="close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form id="groupAddForm">
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="control-label">Наименование группы</label>
                                    <input type="text" name="name" className="form-control"
                                           onChange={this.handleNameChange}/>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Описание группы</label>
                                    <textarea rows="10" name="description" className="form-control"
                                              onChange={this.handleDescriptionChange}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-success" onClick={this.handleSubmit}>
                                    Создать группу
                                </button>
                                <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>
                                    Закрыть окно
                                </button>
                            </div>
                        </form>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

export default AddGroupDialog
