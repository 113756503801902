import React from "react";
import request from "../../utils/request";


class Filter extends React.Component {

    state = {
        bookTypes: [],
        title: this.props.title,
        pubHouse: this.props.pubHouse,
        author: this.props.author,
        yearLeft: this.props.yearLeft,
        yearRight: this.props.yearRight,
        bookType: this.props.bookType,
        compilation: this.props.compilation,
        ugnp: this.props.ugnp,
    }

    handleChangeTitle = (e) => {
        this.setState({title: e.currentTarget.value});
    }

    handleChangePubHouse = (e) => {
        this.setState({pubHouse: e.currentTarget.value});
    }

    handleChangeAuthor = (e) => {
        this.setState({author: e.currentTarget.value});
    }

    handleChangeYearLeft = (e) => {
        this.setState({yearLeft: e.currentTarget.value});
    }

    handleChangeYearRight = (e) => {
        this.setState({yearRight: e.currentTarget.value});
    }

    handleChangeBookType = (e) => {
        this.setState({bookType: e.currentTarget.value});
    }

    handleChangeCompilation = (e) => {
        this.setState({compilation: e.currentTarget.value});
    }

    handleChangeUgnp = (e) => {
        this.setState({ugnp: e.currentTarget.value});
    }

    handleClickClear = (e) => {
        e.preventDefault();
        this.props.callbackClear();
        this.setState({
            title: "",
            pubHouse: "",
            author: "",
            yearLeft: "",
            yearRight: "",
            bookType: "",
            compilation: "",
            ugnp: "",
        });
    }

    handleClickSearch = (e) => {
        e.preventDefault();
        this.props.callbackSearch(
            this.state.title,
            this.state.pubHouse,
            this.state.author,
            this.state.yearLeft,
            this.state.yearRight,
            this.state.bookType,
            this.state.compilation,
            this.state.ugnp
        );
    }

    getBookTypes() {
        return request({
            method: 'GET',
            path: '/books/types'
        })
            .then((resp) => {
                this.setState({
                    bookTypes: resp.data.items
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    componentDidMount() {
        this.getBookTypes().then(value => {
        });
    }

    render() {
        return (
            <form
                style={{marginBottom: "40px"}}>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="inputTitle">Наименование</label>
                        <input type="text"
                               className="form-control"
                               id="inputTitle"
                               onChange={this.handleChangeTitle}
                               value={this.state.title}
                               placeholder="Поиск по наименованию..."/>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputPubHouse">Издательство</label>
                        <input type="text"
                               className="form-control"
                               id="inputPubHouse"
                               onChange={this.handleChangePubHouse}
                               value={this.state.pubHouse}
                               placeholder="Поиск по издательству..."/>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputAuthor">Автор</label>
                        <input type="text"
                               className="form-control"
                               id="inputAuthor"
                               onChange={this.handleChangeAuthor}
                               value={this.state.author}
                               placeholder="Поиск по ФИО автора..."/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-2">
                        <label htmlFor="inputYearLeft">Год от</label>
                        <input type="number"
                               className="form-control"
                               id="inputYearLeft"
                               min="1900" max="2099"
                               value={this.state.yearLeft}
                               onChange={this.handleChangeYearLeft}/>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="inputYearRight">Год до</label>
                        <input type="number"
                               className="form-control"
                               id="inputYearRight"
                               min="1900" max="2099"
                               value={this.state.yearRight}
                               onChange={this.handleChangeYearRight}/>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputType">Тип издания</label>
                        <select id="inputType"
                                className="form-control"
                                onChange={this.handleChangeBookType}>
                            <option selected disabled hidden>Уточнить тип издания</option>
                            {
                                this.state.bookTypes.map(item =>
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                            }

                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputCompilation">Подборки изданий</label>
                        <select id="inputCompilation"
                                className="form-control"
                                onChange={this.handleChangeCompilation}>
                            <option selected disabled hidden>Подборки изданий</option>
                            <option value="1">Выбор пользователей</option>
                            <option value="2">Новинки</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="inputUgnp">Тип издания</label>
                        <select id="inputUgnp"
                                className="form-control"
                                onChange={this.handleChangeUgnp}
                                value={this.state.ugnp}>
                            <option value="" selected disabled hidden>Список УГНП</option>
                            <option value="00">Полный каталог</option>
                            <option value="01">Математика и механика</option>
                            <option value="02">Компьютерные и информационные науки</option>
                            <option value="03">Физика и астрономия</option>
                            <option value="04">Химия</option>
                            <option value="05">Науки о земле</option>
                            <option value="06">Биологические науки</option>
                            <option value="07">Архитектура</option>
                            <option value="08">Техника и технологии строительства</option>
                            <option value="09">Информатика и вычислительная техника</option>
                            <option value="10">Информационная безопасность</option>
                            <option value="11">Электроника, радиотехника и системы связи</option>
                            <option value="12">Фотоника, приборостроение, оптические и биотехнические системы и
                                технологии
                            </option>
                            <option value="13">Электро- и теплоэнергетика</option>
                            <option value="14">Ядерная энергетика и технологии</option>
                            <option value="15">Машиностроение</option>
                            <option value="16">Физико-технические науки и технологии</option>
                            <option value="17">Оружие и системы вооружения</option>
                            <option value="18">Химические технологии</option>
                            <option value="19">Промышленная экология и биотехнологии</option>
                            <option value="20">Техносферная безопасность и природообустройство</option>
                            <option value="21">Прикладная геология, горное дело, нефтегазовое дело и геодезия</option>
                            <option value="22">Технологии материалов</option>
                            <option value="23">Техника и технологии наземного транспорта</option>
                            <option value="24">Авиационная и ракетно-космическая техника</option>
                            <option value="25">Аэронавигация и эксплуатация авиационной и ракетно-космической техники
                            </option>
                            <option value="26">Техника и технологии кораблестроения и водного транспорта</option>
                            <option value="27">Управление в технических системах</option>
                            <option value="28">Нанотехнологии и наноматериалы</option>
                            <option value="29">Технологии легкой промышленности</option>
                            <option value="30">Фундаментальная медицина</option>
                            <option value="31">Клиническая медицина</option>
                            <option value="32">Науки о здоровье и профилактическая медицина</option>
                            <option value="33">Фармация</option>
                            <option value="34">Сестринское дело</option>
                            <option value="35">Сельское, лесное и рыбное хозяйство</option>
                            <option value="36">Ветеринария и зоотехния</option>
                            <option value="37">Психологические науки</option>
                            <option value="38">Экономика и управление</option>
                            <option value="39">Социология и социальная работа</option>
                            <option value="40">Юриспруденция</option>
                            <option value="41">Политические науки и регионоведение</option>
                            <option value="42">Средства массовой информации и информационно-библиотечное дело
                            </option>
                            <option value="43">Сервис и туризм</option>
                            <option value="44">Образование и педагогические науки</option>
                            <option value="45">Языкознание и литературоведение</option>
                            <option value="46">История и археология</option>
                            <option value="47">Философия, этика и религиоведение</option>
                            <option value="48">Теология</option>
                            <option value="49">Физическая культура и спорт</option>
                            <option value="50">Искусствознание</option>
                            <option value="51">Культуроведение и социокультурные проекты</option>
                            <option value="52">Сценические искусства и литературное творчество</option>
                            <option value="53">Музыкальное искусство</option>
                            <option value="54">Изобразительное и прикладные виды искусств</option>
                            <option value="55">Экранные искусства</option>
                        </select>
                    </div>
                    <div className="form-group col-md-4">

                    </div>
                    <div className="form-group col-md-4">
                        <label>Действия</label>
                        <div className="btn-group">
                            <button type="button"
                                    className="btn btn-block btn-outline-warning"
                                    onClick={this.handleClickClear}>
                                Очистить
                            </button>
                            <button type="button"
                                    className="btn btn-success"
                                    onClick={this.handleClickSearch}>
                                Применить
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

}

export default Filter