import qsStringify from './queryStringify';
import appSession from '../stores/AppSession';

export default function request(options) {
    const apiRoot = 'https://vkapi.iprmedia.ru/api';
    const {path, method, body, params, token} = Object.assign(
        {
            method: 'GET',
            token: appSession.token
        },
        options
    );

    const headers = Object.assign(
        {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        options.headers || {}
    );

    const paramsPath = Object.keys(params || {}).length
        ? `?${qsStringify(params)}`
        : '';

    const endpoint = `${apiRoot}/${path.replace(/^\//, '')}${paramsPath}`;

    if (token) headers.Authorization = `Bearer ${token}`;
    let promise;
    if (method.toUpperCase() === 'GET') {
        promise = fetch(endpoint, {headers});
    } else {
        promise = fetch(endpoint, {method, headers, body: JSON.stringify(body)});
    }

    return promise.then((res) => {
        if (res.status === 204) return undefined;

        if (res.status === 401) {
            appSession.reset();
            window.location.href = '/';
            return undefined
        }


        if (res.headers.get('Content-Type').indexOf('zip') > -1 || res.headers.get('Content-Type').indexOf('rar') > -1) {
            res.blob().then(value => {
                var downloadUrl = window.URL.createObjectURL(value);
                var tempLink = document.createElement('a');

                tempLink.href = downloadUrl;
                let result = '';
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                const charactersLength = characters.length;
                for (let i = 0; i < 10; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                tempLink.setAttribute('download', result + '.' + (res.headers.get('Content-Type').indexOf('zip') > -1 ? "zip" : "rar"));
                tempLink.click();
            });

            return res;
        }

        return res.text().then((response) => {
            let json;
            try {
                json = JSON.parse(response);
            } catch (e) {
                throw new Error('Bad JSON response from API');
            }
            if (!json) throw new Error('Null JSON response from API');
            const {error} = json;
            if (error) {
                const err = new Error(error.message);
                err.status = res.status;
                throw err;
            }

            return json;
        });
    });
}