import React from 'react';
import ReactPaginate from "react-paginate";
import request from "../../../utils/request";
import Loader from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons/faLink";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons/faFileDownload";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {Dropdown} from "react-bootstrap";


class Tasks extends React.Component {

    state = {
        items: [],
        total: 0,
        search: "",
        typingTimeout: 0,
        countPerPage: 10,
        currentPage: 0,
        isLoading: true,
        searchChanged: false,
        sort: "1"
    }

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected,
        });
        this.getItems(
            this.state.countPerPage,
            this.state.countPerPage * this.state.currentPage,
            this.state.search,
            this.state.sort
        )
            .then();
    }

    handleChangeSort = (e) => {
        this.setState({sort: e.currentTarget.value});

        this.getItems(
            this.state.countPerPage,
            this.state.countPerPage * this.state.currentPage,
            this.state.search,
            e.currentTarget.value
        )
            .then();
    }

    handleChangeSearch = (e) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: e.target.value,
            searchChanged: true,
            typingTimeout: setTimeout(function () {
                self.getItems(
                    self.state.countPerPage,
                    self.state.countPerPage * self.state.currentPage,
                    self.state.search,
                    self.state.sort
                )
                    .then();
            }, 800)
        });
    }

    handleDownload = (e) => {
        return request({
            method: 'GET',
            path: '/tasks/download',
            params: {
                'task_id': e.currentTarget.dataset.id
            }
        })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }


    getItems(limit, offset, search = "", sort = "1") {
        this.setState({isLoading: true});
        return request({
            method: 'GET',
            path: '/tasks/s',
            params: {
                'limit': limit,
                'offset': offset,
                'search': search,
                'sort': sort
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false,
                    searchChanged: false,
                })

                this.nameInput.focus();
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    componentDidMount() {
        this.getItems(
            this.state.countPerPage,
            this.state.countPerPage * this.state.currentPage,
            this.state.search,
            this.state.sort
        )
            .then();
    }

    handleClickLink = (e) => {
        var win = window.open(e.currentTarget.dataset.link, '_blank');
        win.focus();
    }

    render() {
        const {items, isLoading} = this.state;

        if (isLoading) {
            return (
                <div className="col-sm-8 pl-0 offset-sm-1">
                    <div>
                        <input type="text"
                               className="form-control"
                               id="inputSearch"
                               onChange={this.handleChangeSearch}
                               value={this.state.search}
                               ref={(input) => {
                                   this.nameInput = input;
                               }}
                               placeholder="Поиск задания по его названию или по названию материала"/>
                    </div>

                    <br/>

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Loader
                            type="BallTriangle"
                            color="#0f7dff"
                            height={50}
                            width={50}/>
                    </div>
                </div>
            );
        }

        return (
            <div className="col-sm-8 pl-0 offset-sm-1">

                <div className="form-row mt-3">
                    <div className="form-group col-md-7">
                        <label htmlFor="inputYearLeft">Поиск</label>
                        <input type="text"
                               className="form-control"
                               id="inputSearch"
                               onChange={this.handleChangeSearch}
                               value={this.state.search}
                               ref={(input) => {
                                   this.nameInput = input;
                               }}
                               placeholder="Название задания или материала"/>
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="inputCompilation">Сортировка</label>
                        <select id="inputCompilation"
                                className="form-control"
                                onChange={this.handleChangeSort}>
                            <option selected={this.state.sort === "1"} value="1">От ближайшего к дальнему</option>
                            <option selected={this.state.sort === "2"} value="2">От дальнего к ближайшему</option>
                        </select>
                    </div>
                </div>

                {
                    items.length > 0 &&
                    <ReactPaginate
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

                <div id="tasks">
                    {
                        items.length > 0 ? (
                            items.map(item =>
                                <div className="task-block" id="group-25" key={item.id}>
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <p className="text-bold">{item.name}</p>
                                            <p>{item.subject}</p>
                                            <a href={"http://www.iprbookshop.ru/" + item.book_id + ".html"}
                                               target="_blank" rel="noopener noreferrer" className="pub-title"
                                               title="Открыть в новой вкладке">
                                                {item.book_title}
                                            </a>

                                            <p>{item.fragment}</p>
                                            <p>До {item.date_exp}</p>
                                            <br/>
                                            <p><b>Преподаватель:</b> {item.owner_fio}</p>
                                            <br/>
                                            <div className="d-inline-flex">
                                                {
                                                    item.links.length > 5 &&
                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic"
                                                                         variant={"btn btn-sm btn-outline-warning mr-1"}>
                                                            <FontAwesomeIcon icon={faLink}/>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>

                                                            {
                                                                item.links.split("*")[0].length > 0 &&
                                                                <Dropdown.Item onClick={this.handleClickLink}
                                                                               data-link={item.links.split("*")[0]}>
                                                                    <FontAwesomeIcon icon={faLink}/> Ссылка на ресурс 1
                                                                </Dropdown.Item>
                                                            }

                                                            {
                                                                item.links.split("*")[1].length > 0 &&
                                                                <Dropdown.Item onClick={this.handleClickLink}
                                                                               data-link={item.links.split("*")[1]}>
                                                                    <FontAwesomeIcon icon={faLink}/> Ссылка на ресурс 2
                                                                </Dropdown.Item>
                                                            }

                                                            {
                                                                item.links.split("*")[2].length > 0 &&
                                                                <Dropdown.Item onClick={this.handleClickLink}
                                                                               data-link={item.links.split("*")[2]}>
                                                                    <FontAwesomeIcon icon={faLink}/> Ссылка на ресурс 3
                                                                </Dropdown.Item>
                                                            }

                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                }

                                                {
                                                    item.file &&
                                                    <button className="btn btn-sm btn-outline-warning mr-1"
                                                            data-id={item.id}
                                                            onClick={this.handleDownload}>
                                                        <FontAwesomeIcon icon={faFileDownload}/>
                                                    </button>
                                                }
                                                <a href={"http://www.iprbookshop.ru/" + item.book_id + ".html"}
                                                   target="_blank" rel="noopener noreferrer"
                                                   className="btn btn-sm btn-outline-warning"
                                                   title="Открыть в новой вкладке">
                                                    <FontAwesomeIcon icon={faBook}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : (
                            this.state.search.length > 0 ? (
                                <div style={{width: "100%"}}>
                                    <br/>
                                    <h3 style={{textAlign: "center"}}>По вашему запросу ничего не найдено</h3>
                                </div>
                            ) : (
                                this.state.searchChanged === false &&
                                <div style={{width: "100%"}}>
                                    <br/>
                                    <h3 style={{textAlign: "center"}}>У Вас нет ни одного задания</h3>
                                </div>
                            )
                        )
                    }

                </div>

                {
                    items.length > 0 &&
                    <ReactPaginate
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

            </div>
        );
    }
}

export default Tasks