import React from 'react';
import ReactPaginate from "react-paginate";
import request from "../../../utils/request";
import Loader from "react-loader-spinner";
import {store} from "react-notifications-component";
import DetailsDialog from "./DetailsDialog";


class Groups extends React.Component {

    state = {
        items: [],
        total: 0,
        countPerPage: 10,
        currentPage: 0,
        invitationCode: "",
        isLoading: true
    }

    onPageChange = (page) => {
        this.setState({currentPage: page.selected});
        this.getItems(this.state.countPerPage, this.state.countPerPage * page.selected).then();
    }

    getItems(limit, offset) {
        this.setState({isLoading: true});

        return request({
            method: 'GET',
            path: '/groups/s',
            params: {
                limit: limit,
                offset: offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    componentDidMount() {
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then();
    }

    handleChangeInvitationCode = (e) => {
        this.setState({invitationCode: e.target.value});
    }

    joinToGroupViaCode = () => {
        return request({
            method: 'GET',
            path: '/groups/join/code',
            params: {
                code: this.state.invitationCode,
            }
        })
            .then((resp) => {
                store.addNotification({
                    message: resp.meta.message,
                    type: resp.meta.success ? "info" : "danger",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

                if (resp.meta.success) {
                    this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then();
                }

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    render() {
        const {items, isLoading} = this.state;

        return (
            <div className="col-sm-8 pl-0 offset-sm-1">

                <div className="row mt-4">
                    <div className="col-md-6">
                        <label htmlFor="inputTitle">Код приглашения в группу</label>
                        <div className="d-inline-flex">
                            <input type="text"
                                   className="form-control text-center"
                                   value={this.state.invitationCode}
                                   onChange={this.handleChangeInvitationCode}/>
                            <button type="button"
                                    className="btn btn-outline-warning ml-2"
                                    onClick={this.joinToGroupViaCode}>
                                Присоединится
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6">Скопируйте и вставьте полученный код приглашения в поле для ввода и
                        нажмите присоединится, чтобы вступить в группу
                        для получения заданий
                    </div>
                </div>

                {
                    this.state.items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

                {
                    isLoading ? (
                        <div style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Loader
                                type="BallTriangle"
                                color="#0f7dff"
                                height={50}
                                width={50}/>
                        </div>
                    ) : (
                        <div id="groups">
                            {
                                items.map(item =>
                                    <div className={"group-block " + (item.s_id && "groupHighlighted")} key={item.id}>
                                        <DetailsDialog
                                            groupId={item.id}
                                            groupDescr={item.description}
                                            groupTitle={item.title}/>
                                    </div>
                                )
                            }
                        </div>
                    )
                }


                {
                    this.state.items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

            </div>
        );
    }
}

export default Groups