import React from 'react';
import ReactPaginate from "react-paginate";
import request from "../../../utils/request";
import Loader from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import AddGroupDialog from "./AddGroupDialog";
import DetailsDialog from "./DetailsDialog";


class Groups extends React.Component {

    state = {
        items: [],
        total: 0,
        countPerPage: 10,
        currentPage: 0,
        isLoading: true
    }

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected,
            isLoading: true
        });
        this.getItems(this.state.countPerPage, this.state.countPerPage * page.selected).then(value => {
        });
    }

    handleCreateGroup = () => {
        this.setState({
            currentPage: 0,
            isLoading: true
        });

        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then(value => {
        });
    }

    handleDeleteGroup = (event) => {
        return request({
            method: 'GET',
            path: '/groups/delete',
            params: {
                group_id: event.currentTarget.dataset.id
            }
        })
            .then((resp) => {
                if (this.state.items.length === 1 && this.state.currentPage > 0) {
                    this.setState({currentPage: this.state.currentPage - 1})
                }
                this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then(value => {

                });
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    getItems(limit, offset) {
        return request({
            method: 'GET',
            path: '/groups',
            params: {
                limit: limit,
                offset: offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then(value => {
        });
    }

    render() {

        const {items, isLoading} = this.state;
        if (isLoading) {
            return (
                <div className="col-sm-8 pl-0 offset-sm-1"
                     style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader
                        type="BallTriangle"
                        color="#0f7dff"
                        height={50}
                        width={50}
                    />
                </div>
            );
        }

        return (
            <div className="col-sm-8 pl-0 offset-sm-1">

                <AddGroupDialog callback={this.handleCreateGroup}/>

                {
                    items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

                <div id="groups">

                    {
                        items.map(item =>
                            <div className="group-block" key={item.id}>
                                <div className="row">
                                    <div className="col-sm-8">
                                        Группа: {item.title}
                                        <br/>
                                        Описание: {item.description}
                                    </div>
                                    <div className="col-sm-4">

                                        <DetailsDialog
                                            groupId={item.id}
                                            groupCode={item.invitation_code}
                                            groupTitle={item.title}>
                                            {
                                                item.owner === 1 &&
                                                <button className="btn btn-block btn-outline-warning"
                                                        onClick={this.handleDeleteGroup} data-id={item.id}>
                                                    <FontAwesomeIcon icon={faTrash}/> Удалить
                                                </button>
                                            }
                                        </DetailsDialog>

                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>

                {
                    items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

            </div>
        );
    }
}

export default Groups