import React, {Component} from "react";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../utils/request";
import {store} from "react-notifications-component";
import AddTaskDialog from "../Teacher/Tasks/AddTaskDialog";


class DashBoardItem extends Component {

    state = {
        visibility: true
    }

    handleCreateTask = () => {

    }

    createFavorite(bookId) {
        return request({
            method: 'GET',
            path: 'favorites/create',
            params: {
                book_id: bookId
            }
        })
            .then((resp) => {
                if (resp.meta.success) {
                    this.setState({visibility: false})
                }

                store.addNotification({
                    message: resp.meta.message,
                    type: resp.meta.success ? "success" : "warning",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    render() {
        const {
            id: Id,
            cover: Cover,
            title: Title,
            pubHouse: PubHouse,
            authors: Authors,
            year: Year,
            inFav: InFav
        } = this.props;

        return <div className="col-6 mb-5">
            <div className="row row-book">
                <div className="col-sm-4">
                    <div className="novelty-img">
                        <a title="Открыть в новой вкладке" href={"http://www.iprbookshop.ru/" + Id + ".html"}
                           target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid"
                                 src={Cover}
                                 alt="Открыть в новом окне"/>
                        </a>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="mb-2">
                        <a href={"http://www.iprbookshop.ru/" + Id + ".html"}
                           target="_blank" rel="noopener noreferrer" className="pub-title"
                           title="Открыть в новой вкладке">
                            {Title}
                        </a>
                    </div>
                    <div className="mb-1">
                        <strong>Издательство:</strong> {PubHouse}
                    </div>
                    <div className="mb-1">
                        <strong>Авторы:</strong> {Authors}
                    </div>
                    <div className="pub-data">
                        <strong>Год издания:</strong> {Year}
                    </div>

                    <br/>

                    {
                        !InFav && this.state.visibility &&
                        <div>
                            <button
                                data-id={Id}
                                onClick={(event => {
                                    this.createFavorite(event.currentTarget.dataset.id);
                                })}
                                className="btn btn-block btn-outline-warning">
                                <FontAwesomeIcon icon={faStar}/> Добавить в избранное
                            </button>
                            <br/>
                        </div>
                    }

                    {
                        this.props.userRole === 1 &&
                        <AddTaskDialog
                            taskId={-1}
                            material={Id}
                            materialName={Title}
                            callback={this.handleCreateTask}/>
                    }
                </div>
            </div>
        </div>;
    }

}

export default DashBoardItem

