import React from 'react';
import Header from "../components/Header";
import NavView from "./NavView";
import Protected from "./routes/Protected";
import Invitations from "../App/Teacher/Invitations";
import Students from "../App/Teacher/Students";
import {HashRouter, Switch} from "react-router-dom";
import RoleSwitchRoute from "./routes/RoleSwitch";
import Favorites from "../App/Favorites"
import TeacherGroups from "../App/Teacher/Groups"
import StudentGroups from "../App/Student/Groups"
import TeacherTasks from "../App/Teacher/Tasks"
import StudentTasks from "../App/Student/Tasks"
import DashBoard from "../App/Dashboard";
import bg from "../assets/img/bg.jpg";


class Page extends React.Component {

    state = {
        items: [],
        total: 0,
        isLoading: true
    }

    render() {
        const pageContentStyle = {
            height: "100%",
            background: "#fff url(" + bg + ") no-repeat top left",
        }

        return <div style={pageContentStyle}>

            <Header/>
            <div className="mt-5 mb-sm-5">
                <div className="container">
                    <div className="row">
                        <HashRouter>
                            <div className="col-sm-3 pr-0">
                                <NavView/>
                            </div>
                            <Switch>
                                <Protected exact path="/" component={DashBoard}/>
                                {/*<Protected exact path="/invitations" component={Invitations}/>*/}
                                <RoleSwitchRoute
                                    exact
                                    path="/groups"
                                    teacher={TeacherGroups}
                                    student={StudentGroups}
                                />
                                <Protected exact path="/students" component={Students}/>
                                <Protected exact path="/favorites" component={Favorites}/>

                                <RoleSwitchRoute
                                    exact
                                    path="/tasks"
                                    teacher={TeacherTasks}
                                    student={StudentTasks}
                                />
                            </Switch>
                        </HashRouter>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Page