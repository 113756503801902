import React from 'react';
import ReactPaginate from "react-paginate";
import request from "../../../utils/request";
import Loader from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import AddTaskDialog from "./AddTaskDialog";
import {store} from "react-notifications-component";
import AssignDialog from "./AssignDialog";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons/faFileDownload";
import {faLink} from "@fortawesome/free-solid-svg-icons/faLink";


class Tasks extends React.Component {

    state = {
        items: [],
        total: 0,
        search: "",
        typingTimeout: 0,
        countPerPage: 10,
        currentPage: 0,
        isLoading: true,
        searchChanged: false,
        sort: "1"
    }

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected
        });
        this.getItems(
            this.state.countPerPage,
            this.state.countPerPage * page.selected,
            this.state.search,
            this.state.sort
        )
            .then();
    }

    handleCreateTask = () => {
        this.setState({
            currentPage: 0,
            search: ""
        });

        this.getItems(
            this.state.countPerPage,
            this.state.countPerPage * this.state.currentPage,
            "",
            this.state.sort
        )
            .then();
    }

    handleAssignTask = (taskId, groupId) => {
        return request({
            method: 'GET',
            path: '/tasks/assign',
            params: {
                task_id: taskId,
                group_id: groupId
            }
        })
            .then((resp) => {
                store.addNotification({
                    message: resp.meta.message,
                    type: "info",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    handleDeleteTask = (event) => {
        return request({
            method: 'GET',
            path: '/tasks/delete',
            params: {
                task_id: event.currentTarget.dataset.id
            }
        })
            .then((resp) => {
                if (this.state.items.length === 1 && this.state.currentPage > 0) {
                    this.setState({currentPage: this.state.currentPage - 1})
                }

                store.addNotification({
                    message: resp.meta.message,
                    type: "info",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

                this.getItems(
                    this.state.countPerPage,
                    this.state.countPerPage * this.state.currentPage,
                    this.state.search,
                    this.state.sort
                ).then();
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    handleChangeSort = (e) => {
        this.setState({sort: e.currentTarget.value});

        this.getItems(
            this.state.countPerPage,
            this.state.countPerPage * this.state.currentPage,
            this.state.search,
            e.currentTarget.value
        )
            .then();
    }

    handleChangeSearch = (e) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: e.target.value,
            searchChanged: true,
            typingTimeout: setTimeout(function () {
                self.getItems(
                    self.state.countPerPage,
                    self.state.countPerPage * self.state.currentPage,
                    self.state.search,
                    self.state.sort,
                ).then();
            }, 800)
        });
    }

    getItems(limit, offset, search = "", sort = "1") {
        this.setState({isLoading: true});

        return request({
            method: 'GET',
            path: '/tasks',
            params: {
                'search': search,
                'sort': sort,
                'limit': limit,
                'offset': offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false,
                    searchChanged: false,
                })

                this.nameInput.focus();
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    componentDidMount() {
        this.getItems(
            this.state.countPerPage,
            this.state.countPerPage * this.state.currentPage,
            this.state.search,
            this.state.sort
        ).then();
    }

    render() {
        const {items, isLoading} = this.state;

        if (isLoading) {
            return (
                <div className="col-sm-8 pl-0 offset-sm-1">
                    <div>
                        <AddTaskDialog
                            taskId={0}
                            name={""}
                            subject={""}
                            material={0}
                            materialName={""}
                            fragment={""}
                            deadline={new Date()}
                            callback={this.handleCreateTask}/>

                        <input type="text"
                               className="form-control"
                               id="inputSearch"
                               onChange={this.handleChangeSearch}
                               value={this.state.search}
                               ref={(input) => {
                                   this.nameInput = input;
                               }}
                               placeholder="Поиск задания по названию"/>
                    </div>

                    <br/>

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Loader
                            type="BallTriangle"
                            color="#0f7dff"
                            height={50}
                            width={50}/>
                    </div>
                </div>
            );
        }

        return (
            <div className="col-sm-8 pl-0 offset-sm-1">

                <AddTaskDialog
                    taskId={0}
                    name={""}
                    subject={""}
                    material={0}
                    materialName={""}
                    fragment={""}
                    deadline={new Date()}
                    fileName={""}
                    link1={""}
                    link2={""}
                    link3={""}
                    callback={this.handleCreateTask}/>


                <div className="form-row mt-3">
                    <div className="form-group col-md-7">
                        <label htmlFor="inputYearLeft">Поиск</label>
                        <input type="text"
                               className="form-control"
                               id="inputSearch"
                               onChange={this.handleChangeSearch}
                               value={this.state.search}
                               ref={(input) => {
                                   this.nameInput = input;
                               }}
                               placeholder="Название задания или материала"/>
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="inputCompilation">Сортировка</label>
                        <select id="inputCompilation"
                                className="form-control"
                                onChange={this.handleChangeSort}>
                            <option selected={this.state.sort === "1"} value="1">От ближайшего к дальнему</option>
                            <option selected={this.state.sort === "2"} value="2">От дальнего к ближайшему</option>
                        </select>
                    </div>
                </div>

                {
                    items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

                <div id="tasks">

                    {
                        items.length > 0 ? (
                            items.map(item =>
                                <div className="task-block" key={item.id}>
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <p className="text-bold">{item.name}</p>
                                            <p>{item.subject}</p>
                                            <p>{item.book_title}</p>
                                            <p>{item.fragment}</p>
                                            <p>Выполнить
                                                до {item.date_exp} {item.stat === 1 && "(срок выполнения истек)"}</p>

                                            {item.links.length > 5 && <FontAwesomeIcon className="mr-3" icon={faLink}/>}
                                            {item.file &&
                                            <FontAwesomeIcon className="mr-3" icon={faFileDownload}/>}
                                            <FontAwesomeIcon icon={faBook}/>
                                        </div>
                                        <div className="col-sm-4">

                                            <AddTaskDialog
                                                taskId={item.id}
                                                name={item.name}
                                                subject={item.subject}
                                                material={item.book_id}
                                                materialName={item.book_title}
                                                fragment={item.fragment}
                                                deadline={new Date(item.deadline)}
                                                fileName={item.file}
                                                link1={item.links.split("*")[0].length > 0 ? item.links.split("*")[0] : ""}
                                                link2={item.links.split("*")[1].length > 0 ? item.links.split("*")[1] : ""}
                                                link3={item.links.split("*")[2].length > 0 ? item.links.split("*")[2] : ""}
                                                callback={this.handleCreateTask}>

                                                <AssignDialog
                                                    taskId={item.id}
                                                    callback={this.handleAssignTask}/>

                                                <button className="btn btn-block btn-outline-warning"
                                                        data-id={item.id}
                                                        onClick={this.handleDeleteTask}>
                                                    <FontAwesomeIcon icon={faTrash}/> Удалить
                                                </button>

                                            </AddTaskDialog>

                                        </div>
                                    </div>
                                </div>
                            )
                        ) : (
                            this.state.search.length > 0 ? (
                                <div style={{width: "100%"}}>
                                    <br/>
                                    <h3 style={{textAlign: "center"}}>По вашему запросу ничего не найдено</h3>
                                </div>
                            ) : (
                                this.state.searchChanged === false &&
                                <div style={{width: "100%"}}>
                                    <br/>
                                    <h3 style={{textAlign: "center"}}>У Вас нет ни одного задания</h3>
                                </div>
                            )
                        )

                    }

                </div>

                {
                    items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

            </div>
        );
    }
}

export default Tasks