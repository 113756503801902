import React, {Component} from 'react'
import ReactModal from 'react-modal';
import defaultImage from '../../../assets/img/default.png'
import request from "../../../utils/request";
import ReactPaginate from "react-paginate";
import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";

class DetailsDialog extends Component {

    state = {
        showModal: false,
        teacherImg: defaultImage,
        teacherName: "",
        items: [],
        total: 0,
        countPerPage: 6,
        currentPage: 0
    };

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected
        });
        this.getItems(this.state.countPerPage, this.state.countPerPage * page.selected).then(value => {
        });
    }

    getItems(limit, offset) {
        return request({
            method: 'GET',
            path: '/groups/students',
            params: {
                group_id: this.props.groupId,
                limit: limit,
                offset: offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    getTeacher() {
        return request({
            method: 'GET',
            path: '/groups/teacher',
            params: {
                group_id: this.props.groupId
            }
        })
            .then((resp) => {
                this.setState({teacherName: resp.data.fio});
                return resp
            })
            .catch((err) => {
                return err;
            });
    }

    handleOpenModal = () => {
        this.setState({showModal: true});
        this.getTeacher().then(r => {
        });
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then(value => {
        });
    }

    handleCloseModal = () => {
        this.setState({showModal: false});
    }


    render() {
        return (
            <div>

                {
                    parseInt(this.props.appSession.role) === 1 ? (
                        <div>
                            <button className="btn btn-block btn-outline-warning"
                                    onClick={this.handleOpenModal}>
                                <FontAwesomeIcon icon={faList}/> Обучающиеся
                            </button>
                            {this.props.children}
                        </div>
                    ) : (
                        <div className="row" onClick={this.handleOpenModal}>
                            <div className="col-sm-12">
                                <div className="col-sm-8">
                                    Группа: {this.props.groupTitle}
                                    <br/>
                                    Описание: {this.props.groupDescr}
                                </div>
                            </div>
                        </div>
                    )
                }


                <ReactModal
                    id={"groupModal"}
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Группа: {this.props.groupTitle}</h5>
                            <button type="button" className="close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body p-5">
                            <h3 className="header-light mb-3">Куратор</h3>
                            <div className="row">
                                <div className="col-sm-3">
                                    <img src={this.state.teacherImg} className="rounded-circle" alt=""/>
                                </div>
                                <div className="col-sm-9 pt-5">
                                    {this.state.teacherName}
                                </div>
                            </div>
                            <h3 className="header-light mt-5">Участники</h3>
                            <ReactPaginate
                                forcePage={this.state.currentPage}
                                pageCount={this.state.total / this.state.countPerPage}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                previousLabel={"<"}
                                nextLabel={">"}
                                containerClassName={"pagination justify-content-center m-4"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                activeClassName={"active"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link prev"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link next"}
                                onPageChange={this.onPageChange}/>
                            <div className="row">
                                {
                                    this.state.items.map(item =>
                                        <div className="col-6 my-3" key={item.id}>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <img src={defaultImage} className="img-fluid rounded-circle"
                                                         alt=""/>
                                                </div>
                                                <div className="col-sm-9 pt-4">
                                                    {item.name}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>
                                Закрыть окно
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

export default inject('appSession')(observer(DetailsDialog))
