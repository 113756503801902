import React from 'react';
import {observer, inject} from 'mobx-react';
import AuthContainer from '../../../components/AuthContainer';
import LogoTitle from "../../../components/LogoTitle";
import {Link} from "react-router-dom";
import history from "../../history";
import {store} from "react-notifications-component";
import request from "../../../utils/request";
import {Form} from "semantic-ui-react";


class Login extends React.Component {

    state = {
        status: true,
        userId: 0,
        email: "",
        password: "",
        newPassword: "",
        needReset: 0
    };

    handleChangeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    handleChangePassword = (event) => {
        this.setState({password: event.target.value});
    }

    handleChangeNewPassword = (event) => {
        this.setState({newPassword: event.target.value});
    }

    handleChangeNewPass = () => {
        return request({
            method: 'POST',
            path: '/auth/password/change',
            body: {
                user_id: this.state.userId,
                new_password: this.state.newPassword,
            }
        })
            .then((resp) => {
                store.addNotification({
                    message: resp.meta.message,
                    type: resp.meta.success ? "success" : "warning",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

                if (resp.meta.success) {
                    this.setState({password: this.state.newPassword})
                }

                this.handleLogin();

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    handleLogin = () => {
        return this.props.auth.login(this.state).then((result) => {
            if (result instanceof Error || !result.meta.success) {
                store.addNotification({
                    message: result.meta.message,
                    type: "warning",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });
                return;
            }

            if (result.data.need_reset) {
                this.setState({
                    needReset: result.data.need_reset,
                    userId: result.data.user_id
                })
                this.props.appSession.reset();
                return;
            }

            this.props.auth.me().then(value => {
                history.push('/');
            })
        });
    };

    render() {
        if (this.state.needReset === 1) {
            return (
                <AuthContainer>

                    <LogoTitle title="смена временного пароля" subtitle="введите новый пароль"/>
                    <Form>
                        <label htmlFor="inputPassword" className="sr-only">Новый пароль</label>
                        <input type="password" id="inputNewPassword" className="form-control mb-4"
                               onChange={this.handleChangeNewPassword}
                               placeholder="Новый пароль"
                               required=""/>

                        <button className="btn btn-lg btn-success btn-block text-uppercase"
                                onClick={this.handleChangeNewPass}>
                            Изменить
                        </button>
                    </Form>
                </AuthContainer>
            );
        }

        return (
            <AuthContainer>

                <LogoTitle title="авторизация" subtitle="для работы с цифровым ресурсом IPR SMART авторизируйтесь"/>
                <Form>
                    <label htmlFor="inputEmail" className="sr-only">Имя пользователя</label>
                    <input type="email" id="inputEmail" className="form-control mb-3" onChange={this.handleChangeEmail}
                           placeholder="Имя пользователя (обычно это email)" required="" autoFocus=""/>

                    <label htmlFor="inputPassword" className="sr-only">Пароль</label>
                    <input type="password" id="inputPassword" className="form-control mb-4"
                           onChange={this.handleChangePassword}
                           placeholder="Ваш пароль"
                           required=""/>

                    <div className="checkbox mb-2">
                        <div className="mb-2 d-block">
                            <Link to="/register" className="text-underline">Пройти регистрацию</Link>
                        </div>
                        <div>
                            <Link to="/recovery" className="btn btn-link btn-default">Забыли пароль?</Link>
                        </div>
                    </div>

                    <button className="btn btn-lg btn-success btn-block text-uppercase"
                            onClick={this.handleLogin}>
                        Авторизоваться
                    </button>
                </Form>
            </AuthContainer>
        );
    }
}

export default inject('auth', 'appSession')(observer(Login));