import React, {Component} from 'react'
import ReactModal from 'react-modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import request from "../../../utils/request";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Dropdown} from "react-bootstrap";
import {store} from "react-notifications-component";

class AddToGroupsDialog extends Component {

    state = {
        items: [],
        checked: [],
        total: 0,
        countPerPage: 5,
        currentPage: 0
    };

    handleOpenModal = () => {
        this.setState({showModal: true});
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then();
    }

    handleCloseModal = () => {
        this.setState({showModal: false});
    }

    handleSubmit = () => {
        return request({
            method: 'POST',
            path: '/students/groups/add',
            body: {
                student_id: this.props.studentId,
                groups: this.state.checked
            }
        })
            .then((resp) => {
                this.handleCloseModal();
                this.setState({checked: []});

                store.addNotification({
                    message: resp.meta.message,
                    type: "info",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected
        });
        this.getItems(this.state.countPerPage, this.state.countPerPage * page.selected).then();
    }

    getItems(limit, offset) {
        return request({
            method: 'GET',
            path: '/groups',
            params: {
                limit: limit,
                offset: offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }


    render() {
        return (
            <div>
                <Dropdown.Item
                    onClick={this.handleOpenModal}>
                    <FontAwesomeIcon icon={faPlus}/> Добавить в группу
                </Dropdown.Item>

                <ReactModal
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    ariaHideApp={false}
                    className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Добавить студента в группы</h5>
                            <button type="button" className="close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form id="groupAddForm">
                            <div className="modal-body">

                                <ReactPaginate
                                    forcePage={this.state.currentPage}
                                    pageCount={this.state.total / this.state.countPerPage}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    containerClassName={"pagination justify-content-center m-4"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link prev"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link next"}
                                    onPageChange={this.onPageChange}/>

                                <div id="groups">
                                    {
                                        this.state.items.map(item =>
                                            <div className="group-block" key={item.id} data-id={item.id}
                                                 onClick={(e) => {
                                                     if (!this.state.checked.includes(item.id)) {
                                                         this.setState(prevState => ({
                                                             checked: [...prevState.checked, item.id]
                                                         }));
                                                     } else {
                                                         let tmp = this.state.checked;
                                                         tmp.splice(tmp.indexOf(item.id), 1)

                                                         this.setState(prevState => ({
                                                             checked: tmp
                                                         }));
                                                     }
                                                 }}>
                                                <div className="row">
                                                    <div className="col-sm-11">
                                                        Группа: {item.title}
                                                        <br/>
                                                        Описание: {item.description}
                                                    </div>
                                                    <div className="col-sm-1" style={{margin: "auto"}}>
                                                        <input
                                                            checked={this.state.checked.includes(item.id)}
                                                            style={{transform: "scale(1.5)"}}
                                                            type="checkbox"/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </form>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleSubmit}>
                                Добавить
                            </button>
                            <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>
                                Закрыть окно
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

export default AddToGroupsDialog