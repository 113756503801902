import React from 'react';
import DashBoardItem from "./DashBoardItem";
import request from "../../utils/request";
import Loader from 'react-loader-spinner'
import ReactPaginate from 'react-paginate';
import CountPerPageSelector from "../../components/CountPerPageSelector";
import {inject, observer} from "mobx-react";
import Filter from "./Filter";


class DashBoard extends React.Component {

    state = {
        items: [],
        total: 0,
        currentPage: 0,
        countPerPage: 20,
        isLoading: true,
        search: "",
        type: 1,

        searchType: 1,

        // search
        title: "",
        pubHouse: "",
        author: "",
        yearLeft: "",
        yearRight: "",
        bookType: "",
        compilation: "",
        ugnp: ""
    }

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected
        })
        if (this.state.searchType === 1) {
            this.getItems(this.state.countPerPage,
                this.state.countPerPage * page.selected,
                this.state.search,
                this.state.type
            ).then(value => {

            });
        } else {
            this.s(
                this.state.title,
                this.state.pubHouse,
                this.state.author,
                this.state.yearLeft,
                this.state.yearRight,
                this.state.bookType,
                this.state.compilation,
                this.state.ugnp,
                this.state.countPerPage,
                this.state.countPerPage * page.selected
            );
        }

    }

    onCountChange = (count) => {
        this.setState({
            currentPage: 0,
            countPerPage: count
        });
        if (this.state.searchType === 1) {
            this.getItems(count, 0, this.state.search, this.state.type).then(value => {

            });
        } else {
            this.s(
                this.state.title,
                this.state.pubHouse,
                this.state.author,
                this.state.yearLeft,
                this.state.yearRight,
                this.state.bookType,
                this.state.compilation,
                this.state.ugnp,
                count,
                0
            );
        }
    }

    getItems(limit, offset, search = '', type = '') {
        this.setState({
            search: search,
            type: type,
            isLoading: true
        })

        return request({
            method: 'GET',
            path: '/books',
            params: {
                'limit': limit,
                'offset': offset,
                'search': search,
                'type': type
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false
                })

                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    search = (
        title,
        pubhouse,
        author,
        yearleft,
        yearright,
        booktype,
        compilation,
        ugnp
    ) => {
        this.setState({
            currentPage: 0,
            isLoading: true,
            title: title,
            pubHouse: pubhouse,
            author: author,
            yearLeft: yearleft,
            yearRight: yearright,
            bookType: booktype,
            compilation: compilation,
            ugnp: ugnp,
            searchType: 2
        });

        this.s(
            title,
            pubhouse,
            author,
            yearleft,
            yearright,
            booktype,
            compilation,
            ugnp,
            this.state.countPerPage,
            0
        )
    }

    s(
        title,
        pubhouse,
        author,
        yearleft,
        yearright,
        booktype,
        compilation,
        ugnp,
        limit,
        offset
    ) {
        return request({
            method: 'GET',
            path: '/books/search',
            params: {
                'title': title,
                'pubhouse': pubhouse,
                'author': author,
                'yearleft': yearleft,
                'yearright': yearright,
                'type': booktype,
                'compilation': compilation,
                'ugnp': ugnp,
                'limit': limit,
                'offset': offset
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    isLoading: false
                })
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }


    clear = () => {
        this.setState({
            items: [],
            total: 0,
            currentPage: 0,
            countPerPage: 20,
            isLoading: true,
            search: "",
            type: 1,

            searchType: 1,

            // search
            title: "",
            pubHouse: "",
            author: "",
            yearLeft: "",
            yearRight: "",
            bookType: "",
            compilation: "",
            ugnp: ""
        });

        this.props.searchStore.reset();

        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then(value => {
        });
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then(value => {
        });
    }

    render() {
        const {action, search, type} = this.props.searchStore;
        const {items, isLoading} = this.state;

        if ((search.length > 3 || search === "") && action === "search" && (type === 1 || type === 2)) {
            this.props.searchStore.setAction("");
            this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage, search, type);
            this.setState({searchType: 1});
        }

        if (action === "home") {
            this.props.searchStore.setAction("");
            this.clear();
        }

        if (isLoading) {
            return (
                <div
                    className="col-sm-8 pl-0 offset-sm-1">
                    <Filter
                        title={this.state.search}
                        pubHouse={this.state.pubHouse}
                        author={this.state.author}
                        yearLeft={this.state.yearLeft}
                        yearRight={this.state.yearRight}
                        bookType={this.state.bookType}
                        compilation={this.state.compilation}
                        ugnp={this.state.ugnp}
                        callbackSearch={this.search}
                        callbackClear={this.clear}
                    />
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Loader
                            type="BallTriangle"
                            color="#0f7dff"
                            height={50}
                            width={50}/>
                    </div>
                </div>
            );
        }

        return (
            <div className="col-sm-8 pl-0 offset-sm-1">
                <Filter
                    title={this.state.search}
                    pubHouse={this.state.pubHouse}
                    author={this.state.author}
                    yearLeft={this.state.yearLeft}
                    yearRight={this.state.yearRight}
                    bookType={this.state.bookType}
                    compilation={this.state.compilation}
                    ugnp={this.state.ugnp}
                    callbackSearch={this.search}
                    callbackClear={this.clear}
                />

                {
                    items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

                <div className="row">
                    {
                        items.length > 0 ? (
                            items.map(item =>
                                <DashBoardItem
                                    key={item.id}
                                    id={item.id}
                                    cover={item.image}
                                    title={item.title}
                                    pubHouse={item.publishers}
                                    authors={item.authors}
                                    year={item.year}
                                    inFav={item.in_fav}
                                    userRole={parseInt(this.props.appSession.role)}
                                />
                            )
                        ) : (
                            <div style={{width: "100%"}}>
                                <h3 style={{textAlign: "center"}}>По вашему запросу ничего не найдено</h3>
                            </div>
                        )

                    }
                </div>
                {
                    items.length > 0 &&
                    < div className="row m-4">
                        <div className="col-9">

                            <ReactPaginate
                                forcePage={this.state.currentPage}
                                pageCount={this.state.total / this.state.countPerPage}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                previousLabel={"<"}
                                nextLabel={">"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                activeClassName={"active"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link prev"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link next"}
                                onPageChange={this.onPageChange}/>

                        </div>
                        <div className="col-3">
                            <CountPerPageSelector
                                count={this.state.countPerPage}
                                onCountChange={this.onCountChange}/>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default inject('searchStore', 'appSession')(observer(DashBoard));