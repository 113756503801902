import React from 'react';
import {observer, inject} from 'mobx-react';
import AuthContainer from '../../../components/AuthContainer';
import LogoTitle from "../../../components/LogoTitle";
import {Link} from "react-router-dom";
import {store} from "react-notifications-component";
import request from "../../../utils/request";


class Recovery extends React.Component {


    state = {
        error: null,
        success: false,
        email: ""
    };

    handleEmailChange = (event) => {
        this.setState({email: event.currentTarget.value});
    }

    handleSubmit = (event) => {
        return request({
            method: 'POST',
            path: '/auth/password/reset',
            params: {
                email: this.state.email,
            }
        })
            .then((resp) => {
                store.addNotification({
                    message: resp.meta.message,
                    type: resp.meta.success ? "success" : "warning",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });
                return resp;
            })
            .catch((err) => {
                return err;
            });
    };

    render() {
        return (
            <AuthContainer>
                <LogoTitle title="восстановление пароля"
                           subtitle="Введите адрес электронной почты, который Вы указали при регистрации. Вам будет отправлено сообщение c временным паролем."/>
                <div>
                    <label htmlFor="inputEmail" className="sr-only">Имя пользователя</label>
                    <input type="email" id="inputEmail" className="form-control mb-3"
                           placeholder="Адрес электронной почты" onChange={this.handleEmailChange}/>

                    <div className="checkbox mb-5">
                        <label className="mb-2 d-block">
                            <Link to="/login" className="text-underline">← Вернуться к авторизации</Link>
                        </label>
                    </div>

                    <button className="btn btn-lg btn-success text-uppercase"
                            onClick={this.handleSubmit}>
                        отправить
                    </button>
                </div>
            </AuthContainer>
        );
    }
}

export default inject('auth')(observer(Recovery));
