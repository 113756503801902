import React from 'react';
import {Component} from "react";


class CountPerPageSelector extends Component {

    state = {
        count: this.props.count
    }

    handleClick = (event) => {
        const count = parseInt(event.target.dataset.count)
        this.setState({count: count});
        this.props.onCountChange(count);
    }

    render() {
        return (
            <div>

                <div className="btn-group">
                    <button
                        className={"btn btn-success text-uppercase  " + (this.state.count === 20 ? "active" : "")}
                        data-count="20"
                        onClick={this.handleClick}>
                        20
                    </button>

                    <button
                        className={"btn btn-success text-uppercase  " + (this.state.count === 40 ? "active" : "")}
                        data-count="40"
                        onClick={this.handleClick}>
                        40
                    </button>

                    <button
                        className={"btn btn-success text-uppercase  " + (this.state.count === 60 ? "active" : "")}
                        data-count="60"
                        onClick={this.handleClick}>
                        60
                    </button>
                </div>


            </div>
        );
    }

}

export default CountPerPageSelector