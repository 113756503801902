import React from 'react';
import Logo from '../assets/img/logotype.png';


export default ({title, subtitle}) => {
    return (
        <div>
            <img className="mb-5 img-fluid" src={Logo} alt="IPR Smart"/>
            <h1 className="mb-3 font-weight-semibold text-uppercase">{title}</h1>
            <h5 className="mb-5 font-weight-normal">{subtitle}</h5>
        </div>
    );
};