import React from 'react';
import ReactPaginate from "react-paginate";
import request from "../../utils/request";
import Loader from "react-loader-spinner";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faTrash} from "@fortawesome/free-solid-svg-icons";
import AddTaskDialog from "../Teacher/Tasks/AddTaskDialog";
import {inject, observer} from "mobx-react";


class Favorites extends React.Component {

    state = {
        items: [],
        total: 100,
        search: "",
        typingTimeout: 0,
        countPerPage: 10,
        currentPage: 0,
        isLoading: true,
        searchChanged: false
    }

    onPageChange = (page) => {
        this.setState({
            currentPage: page.selected
        });
        this.getItems(this.state.countPerPage, this.state.countPerPage * page.selected).then();
    }

    handleDeleteFavorite = (event) => {
        return request({
            method: 'GET',
            path: '/favorites/delete',
            params: {
                favorites_id: event.currentTarget.dataset.id
            }
        })
            .then((resp) => {
                if (this.state.items.length === 1 && this.state.currentPage > 0) {
                    this.setState({currentPage: this.state.currentPage - 1})
                }

                this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then();
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    handleChangeSearch = (e) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: e.target.value,
            searchChanged: true,
            typingTimeout: setTimeout(function () {
                self.getItems(self.state.countPerPage, self.state.countPerPage * self.state.currentPage, self.state.search).then();
            }, 800)
        });
    }

    getItems(limit, offset, search = "") {
        this.setState({isLoading: true});
        return request({
            method: 'GET',
            path: '/favorites',
            params: {
                limit: limit,
                offset: offset,
                search: search
            }
        })
            .then((resp) => {
                this.setState({
                    items: resp.data.items,
                    total: resp.data.total,
                    searchChanged: false,
                    isLoading: false
                })

                this.nameInput.focus();
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    componentDidMount() {
        this.getItems(this.state.countPerPage, this.state.countPerPage * this.state.currentPage).then();
    }

    render() {
        const {items, isLoading} = this.state;

        if (isLoading) {
            return (
                <div className="col-sm-8 pl-0 offset-sm-1">
                    <div>
                        <input type="text"
                               className="form-control"
                               id="inputSearch"
                               onChange={this.handleChangeSearch}
                               value={this.state.search}
                               ref={(input) => {
                                   this.nameInput = input;
                               }}
                               placeholder="Поиск по названию"/>
                    </div>

                    <br/>

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Loader
                            type="BallTriangle"
                            color="#0f7dff"
                            height={50}
                            width={50}/>
                    </div>
                </div>
            );
        }

        return (
            <div className="col-sm-8 pl-0 offset-sm-1">
                <input type="text"
                       className="form-control"
                       id="inputSearch"
                       onChange={this.handleChangeSearch}
                       value={this.state.search}
                       ref={(input) => {
                           this.nameInput = input;
                       }}
                       placeholder="Поиск по названию"/>

                {
                    items.length > 0 ? (
                        <ReactPaginate
                            forcePage={this.state.currentPage}
                            pageCount={this.state.total / this.state.countPerPage}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            previousLabel={"<"}
                            nextLabel={">"}
                            containerClassName={"pagination justify-content-center m-4"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            activeClassName={"active"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link prev"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link next"}
                            onPageChange={this.onPageChange}/>
                    ) : (
                        <br/>
                    )
                }

                {
                    items.length > 0 ? (
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Наименование</th>
                                <th>Издательство</th>
                                <th>Автор</th>
                                <th>Год издания</th>
                                <th>Действия</th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                items.map((item, index, arr) =>
                                    <tr key={item.favorite_id}>
                                        <td>{this.state.currentPage * 10 + index + 1}</td>
                                        <td>
                                            <a href={"http://www.iprbookshop.ru/" + item.id + ".html"}
                                               target="_blank" rel="noopener noreferrer" className="pub-title"
                                               title="Открыть в новой вкладке">
                                                {item.title}
                                            </a>
                                        </td>
                                        <td>{item.publishers}</td>
                                        <td>{item.authors}</td>
                                        <td>{item.year}</td>
                                        <td>

                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic"
                                                                 variant={"btn btn-block btn-outline-warning"}>
                                                    <FontAwesomeIcon icon={faCog}/> Действия
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item data-id={item.favorite_id}
                                                                   onClick={this.handleDeleteFavorite}>
                                                        <FontAwesomeIcon icon={faTrash}/> Удалить
                                                    </Dropdown.Item>

                                                    {
                                                        parseInt(this.props.appSession.role) === 1 &&
                                                        <AddTaskDialog
                                                            taskId={-2}
                                                            material={item.id}
                                                            materialName={item.title}/>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </td>
                                    </tr>
                                )
                            }

                            </tbody>
                        </table>
                    ) : (
                        this.state.search.length > 0 ? (
                            <div style={{width: "100%"}}>
                                <br/>
                                <h3 style={{textAlign: "center"}}>По вашему запросу ничего не найдено</h3>
                            </div>
                        ) : (
                            this.state.searchChanged === false &&
                            <div style={{width: "100%"}}>
                                <br/>
                                <h3 style={{textAlign: "center"}}>У Вас нет ни одной закладки</h3>
                            </div>
                        )
                    )
                }

                {
                    items.length > 0 &&
                    <ReactPaginate
                        forcePage={this.state.currentPage}
                        pageCount={this.state.total / this.state.countPerPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination justify-content-center m-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link prev"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next"}
                        onPageChange={this.onPageChange}/>
                }

            </div>
        );
    }
}

export default inject('appSession')(observer(Favorites));