import React, {Component} from 'react'
import ReactModal from 'react-modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTasks} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import BookAutocomplete from "../../../components/BookAutocomplete";
import {store} from "react-notifications-component";
import {Dropdown} from "react-bootstrap";
import appSession from '../../../stores/AppSession';
import axios from 'axios';
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";


class AddTaskDialog extends Component {

    state = {
        showModal: false,
        taskId: this.props.taskId,
        name: this.props.name,
        subject: this.props.subject,
        material: this.props.material,
        materialName: this.props.materialName,
        fragment: this.props.fragment,
        deadline: this.props.deadline,
        fileName: this.props.fileName,
        file: null,
        link1: this.props.link1,
        link2: this.props.link2,
        link3: this.props.link3,
    };

    handleChangeLink1 = (e) => {
        this.setState({link1: e.currentTarget.value});
    }
    handleChangeLink2 = (e) => {
        this.setState({link2: e.currentTarget.value});
    }
    handleChangeLink3 = (e) => {
        this.setState({link3: e.currentTarget.value});
    }

    showError(text) {
        store.addNotification({
            message: text,
            type: "danger",
            insert: "top",
            container: "bottom-center",
            dismiss: {
                duration: 2000,
            }
        });
    }

    handleChangeFile = (e) => {
        var file = e.target.files[0];

        if (!file)
            return;

        if (file.size > 10485760) {
            this.showError("Недопустимый размер файла")
            this.setState({fileName: "*.zip, *.rar, размер < 10Mb"});
            e.currentTarget.value = "";
        } else if (!file.name.includes('.zip') && !file.name.includes('.rar')) {
            this.showError("Недопустимый формат файла");
            this.setState({fileName: "*.zip, *.rar, размер < 10Mb"});
            e.currentTarget.value = "";
        } else {
            this.setState({
                fileName: file.name,
                file: file
            });
        }
    }

    handleOpenModal = () => {
        this.setState({showModal: true});
    }

    handleCloseModal = () => {
        this.setState({showModal: false});
    }

    validate() {
        if (this.state.name.length === 0) {
            this.showError("Не заполнено наименование");
            return false;
        }

        if (this.state.subject.length === 0) {
            this.showError("Не заполнена дисциплина");
            return false;
        }

        if (this.state.material <= 0) {
            this.showError("Выберите материал из доступных изданий");
            return false;
        }

        if (this.state.fragment.length === 0) {
            this.showError("Не заполнен фрагмент");
            return false;
        }

        if (this.state.link1.length > 0 && !this.validUrl(this.state.link1)) {
            this.showError("Ссылка 1 не корректна");
            return false;
        }

        if (this.state.link2.length > 0 && !this.validUrl(this.state.link2)) {
            this.showError("Ссылка 2 не корректна");
            return false;
        }

        if (this.state.link3.length > 0 && !this.validUrl(this.state.link3)) {
            this.showError("Ссылка 3 не корректна");
            return false;
        }

        return true;
    }

    validUrl(url) {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + //port
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i');
        return pattern.test(url);
    }


    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.validate()) {
            return;
        }

        const data = new FormData()
        data.append('task_id', this.state.taskId);
        data.append('name', this.state.name);
        data.append('subject', this.state.subject);
        data.append('material', this.state.material);
        data.append('fragment', this.state.fragment);
        data.append('deadline', this.state.deadline.getTime() / 1000);
        data.append('links', this.state.link1 + "*" + this.state.link2 + "*" + this.state.link3);
        data.append('file_name', this.state.fileName);
        data.append('file', this.state.file);

        const headers = {
            Authorization: `Bearer ` + appSession.token
        };

        axios.post("https://vkapi.iprmedia.ru/api/tasks/create", data, {
            headers: headers
        })
            .then(resp => {
                this.props.callback();
                this.handleCloseModal();

                store.addNotification({
                    message: resp.data.meta.message,
                    type: "info",
                    insert: "top",
                    container: "bottom-center",
                    dismiss: {
                        duration: 2000,
                    }
                });
            });
    }

    handleNameChange = (event) => {
        this.setState({name: event.target.value});
    }

    handleSubjectChange = (event) => {
        this.setState({subject: event.target.value});
    }

    handleMaterialChange = (item) => {
        this.setState({material: item.id});
    }

    handleFragmentChange = (event) => {
        this.setState({fragment: event.target.value});
    }

    handleDateChange = date => {
        this.setState({
            deadline: date
        });
    };

    render() {
        return (
            <div>
                {
                    this.props.taskId > 0 ? (
                        <div>
                            <button className="btn btn-block btn-outline-warning" onClick={this.handleOpenModal}>
                                <FontAwesomeIcon icon={faPencilAlt}/> Редактировать
                            </button>
                            {this.props.children}
                        </div>
                    ) : (
                        this.props.taskId < 0 ? (
                            this.props.taskId === -1 ? (
                                <button className="btn btn-block btn-outline-warning" onClick={this.handleOpenModal}>
                                    <FontAwesomeIcon icon={faTasks}/> Добавить в задания
                                </button>
                            ) : (
                                <Dropdown.Item onClick={this.handleOpenModal}>
                                    <FontAwesomeIcon icon={faTasks}/> Добавить в задания
                                </Dropdown.Item>
                            )

                        ) : (
                            <button type="button" className="btn btn-outline-warning mb-2"
                                    onClick={this.handleOpenModal}>
                                <FontAwesomeIcon icon={faPlus}/> Добавить задание
                            </button>
                        )
                    )
                }

                <ReactModal
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    ariaHideApp={false}
                    className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            {
                                this.props.taskId > 0 ? (
                                    <h5 className="modal-title" id="myModalLabel">Редактирование задания</h5>
                                ) : (
                                    <h5 className="modal-title" id="myModalLabel">Добавление нового задания</h5>
                                )
                            }
                            <button type="button" className="close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form id="groupAddForm">
                            <div className="modal-body">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="control-label">Наименование</label>
                                        <input type="text" name="name" className="form-control"
                                               value={this.state.name}
                                               onChange={this.handleNameChange}/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="control-label">Дисциплина</label>
                                        <input type="text" name="subject" className="form-control"
                                               value={this.state.subject}
                                               onChange={this.handleSubjectChange}/>
                                    </div>
                                </div>
                                <div id={"material"} className="form-group">
                                    <label className="control-label">Материал</label>
                                    <BookAutocomplete
                                        bookId={this.state.material}
                                        bookTitle={this.state.materialName}
                                        onChange={this.handleMaterialChange}/>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-9">
                                        <label className="control-label">Фрагмент</label>
                                        <input type="text" name="fragment" className="form-control"
                                               value={this.state.fragment}
                                               onChange={this.handleFragmentChange}/>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label className="control-label">Срок выполнения</label>
                                        <br/>
                                        <DatePicker
                                            selected={this.state.deadline}
                                            locale={ru}
                                            onChange={this.handleDateChange}
                                            dateFormat="dd.MM.yyyy"
                                            className="form-control"
                                            minDate={new Date()}
                                        />
                                    </div>
                                </div>

                                <label className="control-label">Дополнительные ссылки</label>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <input type="text" name="subject" className="form-control"
                                               value={this.state.link1}
                                               onChange={this.handleChangeLink1}/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <input type="text" name="subject" className="form-control"
                                               value={this.state.link2}
                                               onChange={this.handleChangeLink2}/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <input type="text" name="subject" className="form-control"
                                               value={this.state.link3}
                                               onChange={this.handleChangeLink3}/>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label">Архив с дополнительными материалами</label>
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="customFile"
                                               accept=".zip,.rar"
                                               onChange={this.handleChangeFile}/>
                                        <label className="custom-file-label" htmlFor="customFile">
                                            {this.state.fileName ? this.state.fileName : "*.zip, *.rar, размер < 10Mb"}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {
                                    this.props.taskId === 0 ? (
                                        <button type="submit" className="btn btn-success"
                                                onClick={this.handleSubmit}>
                                            Создать задание
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn btn-success"
                                                onClick={this.handleSubmit}>
                                            Сохранить
                                        </button>
                                    )
                                }
                                <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>
                                    Закрыть окно
                                </button>
                            </div>
                        </form>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

export default AddTaskDialog