import {Component} from 'react'
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faCheck} from "@fortawesome/free-solid-svg-icons";

class Filter extends Component {

    handleShow = (event) => {
        event.preventDefault();
    }

    handleExport = (event) => {
        event.preventDefault();
        this.props.callback();
    }

    render() {
        return (
            <div>
                <h4 className="header-light mt-4">Фильтрация кодов:</h4>
                <form className="form mb-5" id="codes-form">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="control-label">Тип приглашения</label><br/>
                                <select name="web_user_type" className="form-control">
                                    <option value="1">Приглашения для студентов</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="control-label">Действия</label><br/>
                                <button className="btn btn-outline-warning d-inline" onClick={this.handleShow}>
                                    <FontAwesomeIcon icon={faCheck}/> Показать
                                </button>
                                <button className="btn btn-outline-warning d-inline" onClick={this.handleExport}>
                                    <FontAwesomeIcon icon={faFile}/> Экспортировать
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Filter