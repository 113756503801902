import React from 'react';
import Logo from "../../src/assets/img/logotype.png";
import {HashRouter, Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSignOutAlt, faSearch} from '@fortawesome/free-solid-svg-icons'
import {inject, observer} from "mobx-react";
import {Form} from "semantic-ui-react";


class Header extends React.Component {

    handleChangeAnyWordSwitch = (event) => {
        this.setState({match: 1});
        this.props.searchStore.setType(1);
        this.props.searchStore.setAction("search");
    }

    handleChangeExactWordSwitch = (event) => {
        this.setState({match: 2});
        this.props.searchStore.setType(2);
        this.props.searchStore.setAction("search");
    }

    handleChangeSearch = (event) => {
        this.setState({search: event.currentTarget.value})
        this.props.searchStore.setSearch(event.currentTarget.value);
    }

    handleSubmit = (event) => {
        this.props.searchStore.setAction("search");
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmit();
        }
    }

    handleHomeAction = () => {
        this.setState({
            match: 1,
            search: ""
        })
        this.props.searchStore.reset();
        this.props.searchStore.setAction("home");
    }

    render() {
        return (
            <div className={"pt-5"}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 pr-0">
                            <HashRouter>
                                <Link to={"/"} className="logotype" onClick={this.handleHomeAction}>
                                    <img src={Logo} className="img-fluid px-3" alt=""/>
                                </Link>
                            </HashRouter>
                        </div>
                        <div className="col-sm-6 pl-0 offset-sm-1 d-search">
                            <Form className="form text-center text-sm-left">
                                <div className="input-group mb-2">
                                    <input type="text" name="search" className="form-control"
                                           onChange={this.handleChangeSearch}
                                           onKeyPress={this.handleKeyPress}
                                           value={this.props.searchStore.search}
                                           placeholder="Интуитивный поиск изданий..."/>
                                    <div className="input-group-append" style={{zIndex: 0}}>
                                        <HashRouter>
                                            <Link to={"/"} className="btn btn-md m-0 px-4 py-2 btn-success"
                                                  onClick={this.handleSubmit}
                                                  id="button-search-addon">
                                                <FontAwesomeIcon style={{verticalAlign: "bottom"}} icon={faSearch}/>
                                            </Link>
                                        </HashRouter>
                                    </div>
                                </div>
                                <div className="custom-control custom-control-inline custom-switch d-inline">
                                    <input className="custom-control-input" type="radio" id="rsearch1"
                                           name="rsearch"
                                           onChange={this.handleChangeAnyWordSwitch}
                                           onClick={this.handleChangeAnyWordSwitch}
                                           checked={this.props.searchStore.type === 1}/>
                                    <label className="custom-control-label" htmlFor="rsearch1">По любому слову</label>
                                </div>
                                <div className="custom-control custom-control-inline custom-switch d-inline">
                                    <input className="custom-control-input" type="radio" id="rsearch2"
                                           name="rsearch"
                                           onChange={this.handleChangeExactWordSwitch}
                                           onClick={this.handleChangeExactWordSwitch}
                                           checked={this.props.searchStore.type === 2}/>
                                    <label className="custom-control-label" htmlFor="rsearch2">По точному
                                        совпадению</label>
                                </div>
                            </Form>
                        </div>
                        <div className="col-sm-2">
                            <form className="form text-center" method="post">
                                <Link to={"/logout"} className="btn btn-lg btn-outline-warning text-uppercase"
                                      type="submit">
                                    Выйти
                                </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default inject('searchStore')(observer(Header));