import React from 'react';
import {inject} from "mobx-react";

class Logout extends React.Component {

    componentDidMount() {
        this.props.appSession.reset();
        window.location.href = '/';
    }

    render() {
        return <div/>;
    }

}

export default inject('appSession')(Logout)